import "./header.css";
import React, { useCallback, useState } from "react";
import { Row, Menu, Dropdown } from "antd";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const ProductCategory = (props) => {
    const { category, setFilterCategory } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [baseIndex, setSaseIndex] = useState(-1)
    const [linkTag, setLinkTag] = useState(false)
    const filter = useSelector((state) => state.productFilter);

    const onClickSeconMenu = useCallback(
        (child2, index) => {
            setLinkTag(false);
            setSaseIndex(index);
            navigate(`/category/${child2?.slug}/${child2?.id}`);
            dispatch(setFilterCategory({ data: child2.id, parent_id: child2.parent_id, categoryName: child2.name, image_url: child2.image_url }));
        },
        [dispatch, navigate]
    );
    const onClickHomeLink = () => {
        setLinkTag(true)
        setSaseIndex(-1);
    }

    const firstMenu = useCallback(
        (propsItem, index) => {
            return (
                <Menu>
                    {propsItem?.name !== "Grocery2" &&
                        propsItem?.has_child &&
                        propsItem?.cat_active_childs?.map((child2) => {
                            return (
                                <Menu.Item key={child2?.id}>
                                    <span class="ant-dropdown-trigger" onClick={() => onClickSeconMenu(child2, index)}>{child2?.name}</span>
                                </Menu.Item>
                            );
                        })}
                </Menu>
            );
        },
        []
    );

    return (
        <div className="container-fluid">
        <Row style={{ display: "flex", flexWrap: "wrap", alignItems: "center", width: '100%' }}>
          <div className="mainLink">
            <Link 
              onClick={onClickHomeLink} 
              className={`linkTag homeLink ${linkTag ? "baseCategory" : ''}`}  
              to="/"
            >
              Home
            </Link>
          </div>
        
  


    {category?.category?.map((child1, index) => {
      return (
        <div placement="bottomLeft" className="firstMenu" key={child1?.id}>
          <Dropdown overlay={firstMenu(child1)} className={!linkTag && (baseIndex === index || child1?.id === filter?.parent_id) ? 'baseCategory' : ''}>
            <span onClick={() => onClickSeconMenu(child1, index)}>
              {child1?.name}
            </span>
          </Dropdown>
        </div>
      );
    })}
  </Row>
</div>



                      

    );
};

export default ProductCategory;
