import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineEye } from "react-icons/ai";
import Loader from '../loader/Loader';
import { BsHeart, BsShare, BsPlus, BsHeartFill } from "react-icons/bs";
import { BiMinus, BiLink } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import api from "../../api/api";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import { setSelectedProductId } from "../../utils/manageLocalStorage";
import { setFilterSort } from "../../model/reducer/productFilterReducer";
import { FacebookIcon, FacebookShareButton, TelegramIcon, TelegramShareButton, WhatsappIcon, WhatsappShareButton, } from "react-share";
import No_Orders from "../../utils/zero-state-screens/No_Orders.svg";
import QuickViewModal from "../product/QuickViewModal";
import { IoIosArrowDown } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { setSelectedProduct } from "../../model/reducer/selectedProduct";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Popup from "../same-seller-popup/Popup";
import { setCart, setCartProducts, setCartSubTotal, setSellerFlag } from "../../model/reducer/cartReducer";
import { setFavouriteLength, setFavouriteProductIds } from "../../model/reducer/favouriteReducer";
import { LuStar } from "react-icons/lu";
import styles from './CategoryProductList.module.css'
import { Button, Select } from "antd";
import Login from "../login/Login";

const CategoryProductList = React.memo(() => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cookies = new Cookies();
  const [offset, seToffset] = useState(0);
  const city = useSelector((state) => state.city);
  const filter = useSelector((state) => state.productFilter);
  const favorite = useSelector((state) => state.favourite);
  const setting = useSelector((state) => state.setting);
  const cart = useSelector((state) => state.cart);
  const user = useSelector((state) => state.user);
  const share_parent_url = `${setting.setting && setting.setting.web_settings.website_url}/product/`;
  let total_products_per_page = 18;
  const [productresult, setproductresult] = useState([]);
  // const [productData, setProductData] = useState([]);
  const [selectedProduct, setselectedProduct] = useState({});
  const [totalProducts, settotalProducts] = useState(0);
  const [isLoader, setisLoader] = useState(false);
  const [minPrice, setMinPrice] = useState(null);
  const [maxPrice, setMaxPrice] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [p_id, setP_id] = useState(0);
  const [p_v_id, setP_V_id] = useState(0);
  const [qnty, setQnty] = useState(0);
  const { Option } = Select;
  const { t } = useTranslation();
  const [LoginshowModal, setLoginshowModal] = useState(false);

  const filterProductsFromApi = async (filter) => {
    setisLoader(true);
    await api
      .getProductbyFilter(
        city?.city?.latitude,
        city?.city?.longitude,
        filter,
        localStorage.getItem("jwt_token")
      )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 1) {
          if (
            minPrice == null &&
            maxPrice == null &&
            filter?.price_filter == null
          ) {
            setMinPrice(result.total_min_price);
            if (result.total_min_price === result.total_max_price) {
              setMaxPrice(result.total_max_price + 100);
            } else {
              setMaxPrice(result.total_max_price);
            }
          }
          const data = result?.data.length > 0 ? result?.data : [];
          if (offset < total_products_per_page) {
            setproductresult([]);
            setproductresult(data);
          } else {
            setproductresult((pre) => [...pre, ...data]);
          }
          settotalProducts(result.total);
        } else {
          setproductresult([]);
          settotalProducts(0);
        }
        setisLoader(false);
      })
      .catch((error) => console.log("error ", error));
  };

  useEffect(() => {
    filterProductsFromApi({
      min_price: filter.price_filter?.min_price,
      max_price: filter.price_filter?.max_price,
      category_ids: filter?.category_id,
      brand_ids: filter?.brand_ids.toString(),
      sort: filter?.sort_filter,
      search: filter?.search,
      limit: total_products_per_page,
      offset: offset,
      sizes: filter?.search_sizes
        ?.filter((obj) => obj.checked)
        .map((obj) => obj["size"])
        .join(","),
      unit_ids: filter?.search_sizes
        ?.filter((obj) => obj.checked)
        .map((obj) => obj["unit_id"])
        .join(","),
      seller_slug: filter?.seller_slug,
      country_id: filter?.country_id,
      section_id: filter?.section_id,
    });
  }, [
    filter.search,
    filter.category_id,
    filter.brand_ids,
    filter.sort_filter,
    filter?.search_sizes,
    filter?.price_filter,
    total_products_per_page,
    offset,
  ]);
  useEffect(() => {
    seToffset(0);
  }, [
    filter.search,
    filter.category_id,
    filter.brand_ids,
    filter.sort_filter,
    filter?.search_sizes,
    filter?.price_filter,
    total_products_per_page,
  ]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const placeHolderImage = (e) => {
    e.target.src = setting.setting?.web_logo;
  };

  const addtoCart = async (product_id, product_variant_id, qty) => {
    setP_id(product_id);
    setP_V_id(product_variant_id);
    setQnty(qty);
    if (!user?.user) {
      setLoginshowModal(true);
    }
    await api
      .addToCart(localStorage.getItem("jwt_token"), product_id, product_variant_id, qty)
      .then((response) => response.json())
      .then(async (result) => {
        if (result.status === 1) {
          // toast.success(result.message);
          if (
            cart?.cartProducts?.find(
              (product) =>
                product?.product_id === product_id &&
                product?.product_variant_id === product_variant_id
            )?.qty === undefined
          ) {
            dispatch(setCart({ data: result }));
            dispatch(setCartSubTotal({ data: result?.data?.sub_total }));
            const updatedCartCount = [
              ...cart?.cartProducts,
              {
                product_id: product_id,
                product_variant_id: product_variant_id,
                qty: qty,
              },
            ];
            dispatch(setCartProducts({ data: updatedCartCount }));
          } else {
            const updatedProducts = cart?.cartProducts?.map((product) => {
              if (
                product.product_id === product_id &&
                product?.product_variant_id === product_variant_id
              ) {
                return { ...product, qty: qty };
              } else {
                return product;
              }
            });
            dispatch(setCart({ data: result }));
            dispatch(setCartProducts({ data: updatedProducts }));
            dispatch(setCartSubTotal({ data: result?.data?.sub_total }));
          }
        } else if (result?.data?.one_seller_error_code === 1) {
          dispatch(setSellerFlag({ data: 1 }));

        }
      });
  };

  //remove from Cart
  const removefromCart = async (product_id, product_variant_id) => {
    await api
      .removeFromCart(localStorage.getItem("jwt_token"), product_id, product_variant_id)
      .then((response) => response.json())
      .then(async (result) => {
        if (result.status === 1) {
          toast.success(result.message);
          const updatedProducts = cart?.cartProducts?.filter(
            (product) =>
              product?.product_id !== product_id &&
              product?.product_variant_id !== product_variant_id
          );
          dispatch(setCartProducts({ data: updatedProducts }));
        } else {
          toast.error(result.message);
        }
      });
  };

  //Add to favorite
  const addToFavorite = async (product_id) => {
    await api
      .addToFavotite(localStorage.getItem("jwt_token"), product_id)
      .then((response) => response.json())
      .then(async (result) => {
        if (result.status === 1) {
          toast.success(result.message);
          const updatedFavouriteProducts = [
            ...favorite.favouriteProductIds,
            product_id,
          ];
          dispatch(setFavouriteProductIds({ data: updatedFavouriteProducts }));
          const updatedFavouriteLength = favorite?.favouritelength + 1;
          dispatch(setFavouriteLength({ data: updatedFavouriteLength }));
        } else {
          toast.error(result.message);
        }
      });
  };

  const removefromFavorite = async (product_id) => {
    await api
      .removeFromFavorite(localStorage.getItem("jwt_token"), product_id)
      .then((response) => response.json())
      .then(async (result) => {
        if (result.status === 1) {
          toast.success(result.message);
          const updatedFavouriteProducts = favorite?.favouriteProductIds.filter(
            (id) => id !== product_id
          );
          dispatch(setFavouriteProductIds({ data: updatedFavouriteProducts }));
          const updatedFavouriteLength = favorite?.favouritelength - 1;
          dispatch(setFavouriteLength({ data: updatedFavouriteLength }));
        } else {
          toast.error(result.message);
        }
      });
  };

  //page change
  const placeholderItems = Array.from({ length: 12 }).map((_, index) => index);
  function getProductQuantities(products) {
    return Object.entries(
      products.reduce((quantities, product) => {
        const existingQty = quantities[product.product_id] || 0;
        return { ...quantities, [product.product_id]: existingQty + product.qty };
      }, {})
    ).map(([productId, qty]) => ({
      product_id: parseInt(productId),
      qty,
    }));
  }

  // useEffect(() => {
  //   if (productData?.length > 0) {
  //     setproductresult((pre) => [...pre, ...productData]);
  //   }
  // }, [productData])

  const onClickShowMore = () => {
    seToffset((pre) => pre + 18)
  }
  return (
    <>
      {isLoader ?
        <Loader width="100%" height="350px" />
        : !(productresult?.length > 0) ? null : <section
          id={styles.productlist}
          className={`${styles.container} container`}
          onContextMenu={() => {
            return false;
          }}
        >
          <div className={`row ${styles.products}`}>
            <div
              className={`d-flex flex-column ${styles.productList_container}`}
              style={{ gap: "20px" }}
            >
              <div className="row">
                {!isLoader && productresult?.length > 0 ? (
                  <>
                    <div className={`${styles.productFilter, styles.filterview} d-flex col-12 flex-row justify-content-between align-items-center filter-view`}>
                      <div className={`${styles.notFound} d-flex gap-3`}>
                        <span className={styles.total_product_count}>
                          {totalProducts} - {t("products_found")}
                        </span>
                      </div>
                      <div className={styles.select}>
                        <Select
                          style={{ width: '169px' }}
                          aria-label="Default select example"
                          defaultValue={filter.sort_filter}
                          onChange={(value) => {
                            dispatch(setFilterSort({ data: value }));
                          }}
                        >
                          <Option value="new">{t("newest_first")}</Option>
                          <Option value="old">{t("oldest_first")}</Option>
                          <Option value="high">{t("high_to_low")}</Option>
                          <Option value="low">{t("low_to_high")}</Option>
                          <Option value="discount">
                            {t("discount_high_to_low")}
                          </Option>
                          <Option value="popular">{t("popularity")}</Option>
                        </Select>
                      </div>
                    </div>
                  </>
                ) : (
                  productresult?.length > 0 && <Skeleton height={49} borderRadius={8} />
                )}
                {productresult === null || isLoader ? (
                  <>
                    <div className={`h-100 ${styles.productList_content}`}>
                      <div className={`${styles.mobileCss} row flex-wrap`}>
                        {placeholderItems.map((index) => (
                          <div
                            key={index}
                            className={`${!filter.grid_view
                              ? `${styles.listview} col-12 list-view`
                              : "col-md-6 col-sm-6 col-lg-3 flex-column mt-3"
                              }`}
                          >
                            <Skeleton
                              height={330}
                              className="mt-3"
                              borderRadius={8}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {productresult?.length > 0 ? (
                      <div className={`h-100 ${styles.productList_content}`}>
                        <div className={`row ${styles.justifycontentcenter} flex-wrap`}>
                          {productresult && productresult?.map((product, index) => (
                            <div
                              key={product?.id}
                              className={`${!filter.grid_view
                                ? `${styles.miniCard, styles.listview} col-12 list-view`
                                : `${styles.miniCard} col-md-6 col-sm-6 col-lg-2 col-12`
                                }`}
                            >
                              <div
                                className={`${styles.productcard} my-3 ${filter.grid_view ? "flex-column " : "my-5"
                                  }`}
                              >
                                <span
                                  className={`${styles.aiEye} border border-light rounded-circle p-2 px-3`}

                                  onClick={(e) => {
                                    e.preventDefault();
                                    setShowModal(true);
                                    setselectedProduct(product);
                                    setP_id(product.id);
                                    setP_V_id(product.variants[0].id);
                                    setQnty(product.variants[0].cart_count + 1);
                                  }}
                                >
                                  <AiOutlineEye />
                                </span>
                                <Link
                                  to={`/product/${product?.slug}`}
                                  className="text-decoration-none text-reset"
                                >
                                  <div
                                    className={`${styles.imagecontainer}  ${!filter.grid_view
                                      ? "border-end col-3 "
                                      : "col-12"
                                      }`}
                                  >
                                    <img
                                      onError={placeHolderImage}
                                      src={product?.image_url}
                                      alt={product?.slug}
                                      className="card-img-top"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        dispatch(
                                          setSelectedProduct({ data: product?.id })
                                        );
                                        setSelectedProductId(product?.id);
                                        navigate(`/product/${product?.slug}`);
                                      }}
                                    />
                                    {!Number(product?.is_unlimited_stock) &&
                                      product.variants[0].status === 0 && (
                                        <div className={styles.out_of_stockOverlay}>
                                          <p className={styles.out_of_stockText}>
                                            {t("out_of_stock")}
                                          </p>
                                        </div>
                                      )}
                                    {filter.grid_view ? (
                                      ""
                                    ) : (
                                      <>
                                        <div className={`d-flex flex-row border-top ${styles.productcardfooter} product-card-footer`}>
                                          <div className="border-end ">
                                            {favorite.favorite &&
                                              favorite.favorite.data.some(
                                                (element) =>
                                                  element.id === product?.id
                                              ) ? (
                                              <button
                                                type="button"
                                                className={`${styles.wishlistproduct} w-200 h-200`}
                                                onClick={() => {
                                                  if (
                                                    localStorage.getItem("jwt_token") !==
                                                    undefined
                                                  ) {
                                                    removefromFavorite(
                                                      product.id
                                                    );
                                                  } else {
                                                    toast.error(
                                                      t(
                                                        "required_login_message_for_cart"
                                                      )
                                                    );
                                                  }
                                                }}
                                              >
                                                <BsHeartFill
                                                  size={22}
                                                  fill="green"
                                                />
                                              </button>
                                            ) : (
                                              <button
                                                key={product.id}
                                                type="button"
                                                className={`${styles.wishlistproduct} w-200 h-200`}
                                                onClick={() => {
                                                  if (
                                                    localStorage.getItem("jwt_token") !==
                                                    undefined
                                                  ) {
                                                    addToFavorite(product.id);
                                                  } else {
                                                    toast.error(
                                                      t(
                                                        "required_login_message_for_cart"
                                                      )
                                                    );
                                                  }
                                                }}
                                              >
                                                <BsHeart size={22} />
                                              </button>
                                            )}
                                          </div>

                                          <div
                                            className="border-end aes"
                                            style={{ flexGrow: "1" }}
                                          >
                                            {product.variants[0].cart_count >
                                              0 ? (
                                              <>
                                                <div
                                                  id={`input-cart-productdetail`}
                                                  className={styles.inputtocart}
                                                >
                                                  <button
                                                    type="button"
                                                    className={styles.wishlistbutton}
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      if (
                                                        product.variants[0]
                                                          .cart_count === 1
                                                      ) {
                                                        removefromCart(
                                                          product.id,
                                                          product.variants[0].id
                                                        );
                                                      } else {
                                                        addtoCart(
                                                          product.id,
                                                          product.variants[0].id,
                                                          product.variants[0]
                                                            .cart_count - 1
                                                        );

                                                      }
                                                    }}
                                                  >
                                                    <BiMinus
                                                      size={20}
                                                      fill="#fff"
                                                    />
                                                  </button>

                                                  <div className="quantity-container text-center">
                                                    <input
                                                      type="number"
                                                      min="1"
                                                      max={
                                                        product.variants[0].stock
                                                      }
                                                      className="quantity-input bg-transparent text-center"
                                                      value={
                                                        product.variants[0]
                                                          .cart_count
                                                      }
                                                      disabled
                                                    />
                                                  </div>
                                                  <button
                                                    type="button"
                                                    className={styles.wishlistbutton}
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      if (
                                                        Number(
                                                          product.is_unlimited_stock
                                                        )
                                                      ) {

                                                      } else {
                                                        if (
                                                          product.variants[0]
                                                            .cart_count >=
                                                          Number(
                                                            product.variants[0]
                                                              .stock
                                                          )
                                                        ) {
                                                          toast.error(
                                                            t(
                                                              "out_of_stock_message"
                                                            )
                                                          );
                                                        } else if (
                                                          product.variants[0]
                                                            .cart_count >=
                                                          Number(
                                                            product.total_allowed_quantity
                                                          )
                                                        ) {
                                                          toast.error(
                                                            "Apologies, maximum product quantity limit reached"
                                                          );
                                                        } else {
                                                          addtoCart(
                                                            product.id,
                                                            product.variants[0]
                                                              .id,
                                                            product.variants[0]
                                                              .cart_count + 1
                                                          );
                                                        }
                                                      }
                                                    }}
                                                  >
                                                    <BsPlus
                                                      size={20}
                                                      fill="#fff"
                                                    />{" "}
                                                  </button>
                                                </div>
                                              </>
                                            ) : (
                                              <>
                                                <button
                                                  type="button"
                                                  id={`Add-to-cart-section${index}`}
                                                  className={`w-100 h-100 add-to-cart ${styles.active} active`}
                                                  onClick={(e) => {
                                                    if (
                                                      localStorage.getItem("jwt_token") !==
                                                      undefined
                                                    ) {
                                                      e.preventDefault();

                                                      if (
                                                        product.variants[0].status
                                                      ) {
                                                        addtoCart(
                                                          product.id,
                                                          product.variants[0].id,
                                                          1
                                                        );
                                                      } else {
                                                        toast.error(
                                                          "oops, limited stock available"
                                                        );
                                                      }
                                                    } else {
                                                      toast.error(
                                                        t(
                                                          "required_login_message_for_cartRedirect"
                                                        )
                                                      );
                                                    }
                                                  }}
                                                  disabled={
                                                    !Number(
                                                      product.is_unlimited_stock
                                                    ) &&
                                                    product.variants[0].status ===
                                                    0
                                                  }
                                                >
                                                  {t("add_to_cart")}
                                                </button>
                                              </>
                                            )}
                                          </div>

                                          <div className="dropup share">
                                            <button
                                              type="button"
                                              className="w-200 h-200"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                            >
                                              <BsShare size={22} />
                                            </button>

                                            <ul className={styles.dropdownmenu}>
                                              <li>
                                                <WhatsappShareButton
                                                  url={`${setting.setting &&
                                                    setting.setting.web_settings
                                                      .website_url
                                                    }product/${product.slug}`}
                                                >
                                                  <WhatsappIcon
                                                    size={32}
                                                    round={true}
                                                  />{" "}
                                                  <span>WhatsApp</span>
                                                </WhatsappShareButton>
                                              </li>
                                              <li>
                                                <TelegramShareButton
                                                  url={`${setting.setting &&
                                                    setting.setting.web_settings
                                                      .website_url
                                                    }product/${product.slug}`}
                                                >
                                                  <TelegramIcon
                                                    size={32}
                                                    round={true}
                                                  />{" "}
                                                  <span>Telegram</span>
                                                </TelegramShareButton>
                                              </li>
                                              <li>
                                                <FacebookShareButton
                                                  url={`${setting.setting &&
                                                    setting.setting.web_settings
                                                      .website_url
                                                    }product/${product.slug}`}
                                                >
                                                  <FacebookIcon
                                                    size={32}
                                                    round={true}
                                                  />{" "}
                                                  <span>Facebook</span>
                                                </FacebookShareButton>
                                              </li>
                                              <li>
                                                <button
                                                  type="button"
                                                  onClick={() => {
                                                    navigator.clipboard.writeText(
                                                      `${setting.setting &&
                                                      setting.setting
                                                        .web_settings
                                                        .website_url
                                                      }product/${product.slug}`
                                                    );
                                                    toast.success(
                                                      "Copied Succesfully!!"
                                                    );
                                                  }}
                                                  className="react-share__ShareButton"
                                                >
                                                  {" "}
                                                  <BiLink />{" "}
                                                  <span>Copy Link</span>
                                                </button>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </div>

                                  <div className={`h-0 card-body ${styles.productcardbody} p-3 `}>
                                    {product?.rating_count ? (
                                      <div className={`${styles.ratings} d-flex align-items-center`}>
                                        <LuStar
                                          className="me-2"
                                          style={{
                                            fill: "#fead0e",
                                            stroke: "#fead0e",
                                          }}
                                        />
                                        <div className={`border-end border-2 pe-2 me-2 ${styles.avgRating}`}>
                                          {product?.average_rating?.toFixed(
                                            setting.setting &&
                                            setting.setting.decimal_point
                                          )}
                                        </div>
                                        <div>{product?.rating_count}</div>
                                      </div>
                                    ) : null}
                                    <h3
                                      onClick={(e) => {
                                        e.preventDefault();
                                        dispatch(
                                          setSelectedProduct({ data: product.id })
                                        );
                                        setSelectedProductId(product.id);
                                        navigate(`/product/${product?.slug}`);
                                      }}
                                    >
                                      {product.name}
                                    </h3>
                                    <div className={styles.price}>
                                      {filter.grid_view ? (
                                        <div>
                                          <span
                                            id={`price${index}-section`}
                                            className="d-flex align-items-center"
                                          >
                                            <p id={`fa-rupee${index}`}>
                                              {setting.setting &&
                                                setting.setting.currency}
                                              {product.variants[0]
                                                .discounted_price === 0
                                                ? product.variants[0].price.toFixed(
                                                  setting.setting &&
                                                  setting.setting
                                                    .decimal_point
                                                )
                                                : product.variants[0].discounted_price.toFixed(
                                                  setting.setting &&
                                                  setting.setting
                                                    .decimal_point
                                                )}
                                            </p>
                                            {product?.variants[0]?.price &&
                                              product?.variants[0]
                                                ?.discounted_price != 0 &&
                                              product?.variants[0]?.price !==
                                              product?.variants[0]
                                                ?.discounted_price ? (
                                              <span
                                                id={`price${index}-section`}
                                                className="d-flex align-items-center"
                                              >
                                                <p
                                                  id="relatedproduct-fa-rupee"
                                                  className="fw-normal text-decoration-line-through m-0"
                                                  style={{
                                                    color:
                                                      "var(--sub-text-color)",
                                                    fontSize: "14px",
                                                  }}
                                                >
                                                  {setting.setting &&
                                                    setting.setting.currency}
                                                  {product?.variants[0]?.price?.toFixed(
                                                    setting.setting &&
                                                    setting.setting
                                                      .decimal_point
                                                  )}
                                                </p>
                                              </span>
                                            ) : null}
                                          </span>
                                          <div className={styles.product_varients_drop}>
                                            <input
                                              type="hidden"
                                              name={`default-variant-id`}
                                              id={`productlist${index}-variant-id`}
                                            />

                                            {product.variants.length > 1 ? (
                                              <>
                                                <div
                                                  className={styles.variant_selection}
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    setselectedProduct(product);
                                                    setShowModal(true);
                                                    setP_id(product.id);
                                                    setP_V_id(
                                                      product.variants[0].id
                                                    );
                                                    setQnty(
                                                      product.variants[0]
                                                        .cart_count + 1
                                                    );
                                                  }}
                                                >
                                                  <span className={styles.product_list_dropdown_span}>
                                                    {
                                                      <>
                                                        {
                                                          product.variants[0]
                                                            .measurement
                                                        }{" "}
                                                        {
                                                          product.variants[0]
                                                            .stock_unit_name
                                                        }{" "}
                                                      </>
                                                    }
                                                  </span>
                                                  <IoIosArrowDown />
                                                </div>
                                              </>
                                            ) : (
                                              <>
                                                <p
                                                  id={`default-product${index}-variant`}
                                                  value={product.variants[0].id}
                                                  className={`${styles.variant_value} select-arrow`}
                                                >
                                                  {product.variants[0]
                                                    .measurement +
                                                    " " +
                                                    product.variants[0]
                                                      .stock_unit_name}
                                                </p>
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      ) : (
                                        <>
                                          <div className={`${styles.product_varients_drop} d-flex align-items-center`}>
                                            {product.variants.length > 1 ? (
                                              <>
                                                <div
                                                  className={styles.variant_selection}
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    setselectedProduct(product);
                                                    setShowModal(true);
                                                    setP_id(product.id);
                                                    setP_V_id(
                                                      product.variants[0].id
                                                    );
                                                    setQnty(
                                                      product.variants[0]
                                                        .cart_count + 1
                                                    );
                                                  }}
                                                >
                                                  <span className={styles.product_list_dropdown_span}>
                                                    {
                                                      <>
                                                        {
                                                          product.variants[0]
                                                            .measurement
                                                        }{" "}
                                                        {
                                                          product.variants[0]
                                                            .stock_unit_name
                                                        }{" "}
                                                        Rs.
                                                        <span
                                                          className={styles.originalprice}
                                                          id={`dropDown-Toggle${index}`}
                                                        >
                                                          {product.variants[0].toFixed(
                                                            setting.setting &&
                                                            setting.setting
                                                              .decimal_point
                                                          )}
                                                        </span>
                                                      </>
                                                    }
                                                  </span>
                                                  <IoIosArrowDown />
                                                </div>
                                              </>
                                            ) : (
                                              <>
                                                <p
                                                  id={`default-product${index}-variant`}
                                                  value={product.variants[0].id}
                                                  className={`${styles.variant_value} select-arrow`}
                                                >
                                                  {product.variants[0]
                                                    .measurement +
                                                    " " +
                                                    product.variants[0]
                                                      .stock_unit_name}
                                                </p>
                                              </>
                                            )}
                                            <span
                                              id={`price${index}-section`}
                                              className="d-flex align-items-center"
                                            >
                                              <p id="fa-rupee">
                                                {setting.setting &&
                                                  setting.setting.currency}
                                              </p>{" "}
                                              {product.variants[0]
                                                .discounted_price === 0
                                                ? product.variants[0].price.toFixed(
                                                  setting.setting &&
                                                  setting.setting
                                                    .decimal_point
                                                )
                                                : product.variants[0].discounted_price.toFixed(
                                                  setting.setting &&
                                                  setting.setting
                                                    .decimal_point
                                                )}
                                            </span>
                                          </div>
                                          <p className={styles.product_list_description}></p>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </Link>
                                {filter.grid_view ? (
                                  <>
                                    <div className={`d-flex flex-row border-top ${styles.productcardfooter} product-card-footer`}>
                                      <div className="border-end ">
                                        {favorite.favorite &&
                                          favorite?.favouriteProductIds?.some(
                                            (id) => id == product.id
                                          ) ? (
                                          <button
                                            type="button"
                                            className={`${styles.wishlistproduct} w-200 h-200`}
                                            onClick={() => {
                                              if (
                                                localStorage.getItem("jwt_token") !==
                                                undefined
                                              ) {
                                                removefromFavorite(product.id);
                                              } else {
                                                toast.error(
                                                  t(
                                                    "required_login_message_for_cart"
                                                  )
                                                );
                                              }
                                            }}
                                          >
                                            <BsHeartFill size={22} fill="green" />
                                          </button>
                                        ) : (
                                          <button
                                            key={product.id}
                                            type="button"
                                            className={`${styles.wishlistproduct} w-200 h-200`}
                                            onClick={() => {
                                              if (
                                                localStorage.getItem("jwt_token") !==
                                                undefined
                                              ) {
                                                addToFavorite(product.id);
                                              } else {
                                                toast.error(
                                                  t(
                                                    "required_login_message_for_cart"
                                                  )
                                                );
                                              }
                                            }}
                                          >
                                            <BsHeart size={22} />
                                          </button>
                                        )}
                                      </div>

                                      <div
                                        className="border-end aes"
                                        style={{ flexGrow: "1" }}
                                      >
                                        {user?.user &&
                                          cart?.cartProducts?.find(
                                            (prdct) =>
                                              prdct?.product_variant_id ==
                                              product?.variants?.[0]?.id
                                          )?.qty > 0 ? (
                                          <>
                                            <div
                                              id={`input-cart-productdetail`}
                                              className={styles.inputtocart}
                                            >
                                              <button
                                                type="button"
                                                className={styles.wishlistbutton}
                                                onClick={() => {
                                                  if (
                                                    cart?.cartProducts?.find(
                                                      (prdct) =>
                                                        prdct?.product_variant_id ==
                                                        product?.variants?.[0]?.id
                                                    )?.qty == 1
                                                  ) {
                                                    removefromCart(
                                                      product.id,
                                                      product.variants[0].id
                                                    );
                                                    // selectedVariant.cart_count = 0;
                                                  } else {
                                                    addtoCart(
                                                      product.id,
                                                      product.variants[0].id,
                                                      cart?.cartProducts?.find(
                                                        (prdct) =>
                                                          prdct?.product_variant_id ==
                                                          product.variants[0].id
                                                      )?.qty - 1
                                                    );

                                                  }
                                                }}
                                              >
                                                <BiMinus size={20} fill="#fff" />
                                              </button>
                                              <div className="quantity-container text-center">
                                                <input
                                                  type="number"
                                                  min="1"
                                                  max={product.variants[0].stock}
                                                  className="quantity-input bg-transparent text-center"
                                                  value={
                                                    cart?.cartProducts?.find(
                                                      (prdct) =>
                                                        prdct?.product_variant_id ==
                                                        product.variants[0].id
                                                    )?.qty
                                                  }
                                                  disabled
                                                />
                                              </div>
                                              <button
                                                type="button"
                                                className={styles.wishlistbutton}
                                                onClick={() => {
                                                  const productQuantity =
                                                    getProductQuantities(
                                                      cart?.cartProducts
                                                    );
                                                  if (
                                                    Number(
                                                      product.is_unlimited_stock
                                                    )
                                                  ) {
                                                    if (
                                                      productQuantity?.find(
                                                        (prdct) =>
                                                          prdct?.product_id ==
                                                          product?.id
                                                      )?.qty <
                                                      Number(
                                                        product?.total_allowed_quantity
                                                      )
                                                    ) {
                                                      addtoCart(
                                                        product.id,
                                                        product.variants[0].id,
                                                        cart?.cartProducts?.find(
                                                          (prdct) =>
                                                            prdct?.product_variant_id ==
                                                            product.variants[0].id
                                                        )?.qty + 1
                                                      );
                                                    } else {
                                                      toast.error(
                                                        "Apologies, maximum product quantity limit reached!"
                                                      );
                                                    }
                                                  } else {
                                                    if (
                                                      productQuantity?.find(
                                                        (prdct) =>
                                                          prdct?.product_id ==
                                                          product?.id
                                                      )?.qty >=
                                                      Number(
                                                        product.variants[0].stock
                                                      )
                                                    ) {
                                                      toast.error(
                                                        t("out_of_stock_message")
                                                      );
                                                    } else if (
                                                      Number(
                                                        productQuantity?.find(
                                                          (prdct) =>
                                                            prdct?.product_id ==
                                                            product?.id
                                                        )?.qty
                                                      ) >=
                                                      Number(
                                                        product.total_allowed_quantity
                                                      )
                                                    ) {
                                                      toast.error(
                                                        "Apologies, maximum product quantity limit reached"
                                                      );
                                                    } else {
                                                      addtoCart(
                                                        product.id,
                                                        product.variants[0].id,
                                                        cart?.cartProducts?.find(
                                                          (prdct) =>
                                                            prdct?.product_variant_id ==
                                                            product.variants[0].id
                                                        )?.qty + 1
                                                      );
                                                    }
                                                  }
                                                }}
                                              >
                                                <BsPlus size={20} fill="#fff" />{" "}
                                              </button>
                                            </div>
                                          </>
                                        ) : (
                                          <>
                                            <button
                                              type="button"
                                              id={`Add-to-cart-section${index}`}
                                              className={`w-100 h-100 add-to-cart ${styles.active} active`}
                                              onClick={() => {
                                                const productQuantity =
                                                  getProductQuantities(
                                                    cart?.cartProducts
                                                  );
                                                if (
                                                  localStorage.getItem("jwt_token") !==
                                                  undefined
                                                ) {
                                                  if (
                                                    (productQuantity?.find(
                                                      (prdct) =>
                                                        prdct?.product_id ==
                                                        product?.id
                                                    )?.qty || 0) >=
                                                    Number(
                                                      product?.total_allowed_quantity
                                                    )
                                                  ) {
                                                    toast.error(
                                                      "Oops, Limited Stock Available"
                                                    );
                                                  } else if (
                                                    Number(
                                                      product.is_unlimited_stock
                                                    )
                                                  ) {
                                                    addtoCart(
                                                      product.id,
                                                      product?.variants?.[0].id,
                                                      1
                                                    );
                                                  } else {
                                                    if (
                                                      product?.variants?.[0]
                                                        ?.status
                                                    ) {
                                                      addtoCart(
                                                        product.id,
                                                        product?.variants?.[0].id,
                                                        1
                                                      );
                                                    } else {
                                                      toast.error(
                                                        "Oops, Limited Stock Available"
                                                      );
                                                    }
                                                  }
                                                } else {
                                                  toast.error(
                                                    t(
                                                      "required_login_message_for_cartRedirect"
                                                    )
                                                  );
                                                }
                                              }}
                                              disabled={
                                                !Number(
                                                  product.is_unlimited_stock
                                                ) &&
                                                product.variants[0].status === 0
                                              }
                                            >
                                              {t("add_to_cart")}
                                            </button>
                                          </>
                                        )}
                                      </div>

                                      <div className="dropup share">
                                        <button
                                          type="button"
                                          className="w-200 h-200"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          <BsShare size={22} />
                                        </button>

                                        <ul className={`${styles.dropdownmenu} dropdown-menu`}>
                                          <li className={styles.dropDownLi}>
                                            <WhatsappShareButton
                                              url={`${share_parent_url}/${product.slug}`}
                                            >
                                              <WhatsappIcon
                                                size={32}
                                                round={true}
                                              />{" "}
                                              <span>WhatsApp</span>
                                            </WhatsappShareButton>
                                          </li>
                                          <li className={styles.dropDownLi}>
                                            <TelegramShareButton
                                              url={`${share_parent_url}/${product.slug}`}
                                            >
                                              <TelegramIcon
                                                size={32}
                                                round={true}
                                              />{" "}
                                              <span>Telegram</span>
                                            </TelegramShareButton>
                                          </li>
                                          <li className={styles.dropDownLi}>
                                            <FacebookShareButton
                                              url={`${share_parent_url}/${product.slug}`}
                                            >
                                              <FacebookIcon
                                                size={32}
                                                round={true}
                                              />{" "}
                                              <span>Facebook</span>
                                            </FacebookShareButton>
                                          </li>
                                          <li>
                                            <button
                                              type="button"
                                              onClick={() => {
                                                navigator.clipboard.writeText(
                                                  `${share_parent_url}/${product.slug}`
                                                );
                                                toast.success(
                                                  "Copied Succesfully!!"
                                                );
                                              }}
                                              className="react-share__ShareButton"
                                            >
                                              {" "}
                                              <BiLink /> {t("tap_to_copy")}
                                            </button>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                        {offset + 18 < totalProducts && <div className={styles.showMore}>
                          <Button danger onClick={onClickShowMore}>Show More</Button>
                        </div>}
                        {LoginshowModal && <Login show={LoginshowModal} setShow={setLoginshowModal} />}
                        <QuickViewModal
                          selectedProduct={selectedProduct}
                          setselectedProduct={setselectedProduct}
                          showModal={showModal}
                          setShowModal={setShowModal}
                          setP_id={setP_id}
                          setP_V_id={setP_V_id}
                        />
                        <Popup
                          product_id={p_id}
                          product_variant_id={p_v_id}
                          quantity={qnty}
                          setisLoader={setisLoader}
                          cookies={cookies}
                          toast={toast}
                          city={city}
                        />
                      </div>
                    ) : (
                      <div className={styles.noproduct}>
                        <img
                          src={No_Orders}
                          style={{ width: "40%" }}
                          alt="no-product"
                          className="img-fluid"
                        ></img>
                        <p>No Products Found</p>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </section>}
    </>
  );
});

export default CategoryProductList;
