import { useEffect, useState } from 'react';
import api from '../api/api';

const useByBlogs = (token, limit, offset,slug) => {
    const [data, setData] = useState(null);
    const [totalData, setTotalData] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchByBlog = async () => {
            setLoading(true);
            try {
                const response = await api.getBlogs(token, limit, offset,slug);
                const result = await response.json();
                setTotalData(result.total);
                setData(result.data);
            } catch (err) {
                setError(err.message);
            }
            setLoading(false);
        };
        fetchByBlog();
    }, [offset]);

    return { data, totalData, loading, error };
};

export default useByBlogs;