import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    status: "loading",
    category: null,
};

export const categoryReducer = createSlice({
    name: "category",
    initialState,
    reducers: {
        setCategory: (state, action) => {
            state.status = "fulfill";
            state.category = action.payload.data;
        },
        setSelectedCategory: (state, action) => {
            state.selectedCategory = action.payload;
        },
    }
});

export const { setCategory, setSelectedCategory } = categoryReducer.actions;
export default categoryReducer.reducer;