import React, { useState, useEffect, useRef } from "react";
import "./header.css";
import { BsShopWindow } from "react-icons/bs";
import { BiUserCircle } from "react-icons/bi";
import { MdSearch, MdGTranslate } from "react-icons/md";
import { IoHeartOutline, IoCartOutline, } from "react-icons/io5";
import { RiAccountCircleFill } from "react-icons/ri";
import { IoMdArrowDropdown, IoIosArrowDown } from "react-icons/io";
import { GoLocation } from "react-icons/go";
import { FiMenu, FiFilter } from "react-icons/fi";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Location from "../location/Location";
import { useDispatch, useSelector } from "react-redux";
import api from "../../api/api";
import Login from "../login/Login";
// import Cookies from "universal-cookie";
import Cart from "../cart/Cart";
// import Suggestion from "../suggestion/Suggestion";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Dropdown } from "react-bootstrap";
import { setCity } from "../../model/reducer/locationReducer";
import { setPaymentSetting } from "../../model/reducer/settingReducer";
import { setLanguage, setLanguageList } from "../../model/reducer/languageReducer";
import { setFilterSearch } from "../../model/reducer/productFilterReducer";
import { Modal } from "antd";
import "../location/location.css";
import { setCategory } from "../../model/reducer/categoryReducer";
import { setFilterCategory } from "../../model/reducer/productFilterReducer";
// import { setFilterBrands } from "../../model/reducer/productFilterReducer";
// import { setFilterMinMaxPrice } from "../../model/reducer/productFilterReducer";
import callImage from "../../utils/image/supportIcon.png";
import callingimg from "../../utils/image/calling12.png";
import whatsappimg from "../../utils/image/whatsapp.png";
import emailimg from "../../utils/image/email.png";
import ProductCategory from "./ProductCategory";
import { Input, AutoComplete, Select as AntdSelect } from 'antd';

const { Option } = AntdSelect;
const { Search } = Input;

const Header = ({ setShowSuggetion, showSuggetion }) => {
  const closeSidebarRef = useRef();
  const searchNavTrigger = useRef();
  const { t } = useTranslation();
  // const cookies = new Cookies();
  const curr_url = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const city = useSelector((state) => state.city);
  const user = useSelector((state) => state.user);
  const cart = useSelector((state) => state.cart);
  const favorite = useSelector((state) => state.favourite);
  const setting = useSelector((state) => state.setting);
  const languages = useSelector((state) => state.language);
  const category = useSelector((state) => state.category);

  // const [isSticky, setIsSticky] = useState(false);
  // const [activeSaggetion, setActiveSaggetion] = useState(false)
  // const [totalNotification, settotalNotification] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [bodyScroll, setBodyScroll] = useState(false);
  const [locModal, setLocModal] = useState(false);
  const [mobileNavActKey, setMobileNavActKey] = useState(null);
  const [isLocationPresent, setisLocationPresent] = useState(false);
  const [isDesktopView, setIsDesktopView] = useState(window.innerWidth > 768);
  // const [search, setsearch] = useState("");
  const [isCartSidebarOpen, setIsCartSidebarOpen] = useState(false);
  // const [suggestions, setSuggestions] = useState(false);
  const [data, setData] = useState("");
  const [options, setOptions] = useState([]);
  const [categoryObject, setCategoryObject] = useState();
  const [searchValue, setSearchValue] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(true);
  const [suggestionCategoryId, setSuggestionCategoryId] = useState('');
  // const flattenCategory = (categoryList) => {
  //   return categoryList?.flatMap(item => {
  //     if (item.cat_active_childs && item.cat_active_childs.length > 0) {
  //       return [item, ...flattenCategory(item.cat_active_childs)];
  //     } else {
  //       return [item];
  //     }
  //   });
  // };
  // useEffect(() => {
  //   if (category && category.category) {
  //     const filterCategory = flattenCategory(category.category);

  //     setSuggestionCategory(filterCategory);
  //   }
  // }, [category]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.fetchSuggestion(suggestionCategoryId.id);
        const result = await response.json();

        setData(result.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [categoryObject?.id]);
  // suggestions for product search


  // to open Location modal
  const openModal = () => {
    handleModal();
  };

  const openCanvasModal = () => {
    handleModal();
    closeSidebarRef.current.click();
  };

  useEffect(() => {
    if (bodyScroll) {
      document.body.style.overflow = "auto";
      document.body.style.height = "100vh";
    } else {
      document.body.style.overflow = "auto";
      document.body.style.height = "auto";
    }
  }, [bodyScroll]);

  const handleModal = () => {
    setLocModal(true);
    setBodyScroll(true);
  };

  // useEffect(() => {
  //   if (curr_url.pathname !== "/products") {
  //     setsearch("");
  //     dispatch(setFilterSearch({ data: null }));
  //   }
  // }, [curr_url]);

  useEffect(() => {
    if (setting.setting?.default_city && city.city == null) {
      setisLocationPresent(true);
      api
        .getCity(
          parseFloat(setting.setting.default_city?.latitude),
          parseFloat(setting.setting.default_city?.longitude)
        )
        .then((response) => response.json())
        .then((result) => {
          if (result.status === 1) {
            dispatch(setCity({ data: result.data }));
          }
        });
      setisLocationPresent(true);
    } else if (
      setting?.setting &&
      setting.setting?.default_city == null &&
      city?.city == null
    ) {
      setLocModal(true);
    }
  }, [setting]);

  useEffect(() => {
    if (languages?.available_languages === null) {
      api
        .getSystemLanguage(0, 0)
        .then((response) => response.json())
        .then((result) => {
          dispatch(setLanguageList({ data: result.data }));
        });
    }
    if (setting?.payment_setting == null) {
      fetchPaymentSetting();
    }
    // dispatch(setFilterSearch({ data: null }));
    handleResize();
    window?.addEventListener("resize", handleResize);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // const fetchCart = async (token, latitude, longitude) => {
  //     await api.getCart(token, latitude, longitude)
  //         .then(response => response.json())
  //         .then(result => {
  //             if (result.status === 1) {
  //                 dispatch(setCart({ data: result }));
  //             }
  //             else {
  //                 dispatch(setCart({ data: null }));
  //             }
  //         })
  //         .catch(error => console.log(error));
  // };

  // const fetchFavorite = async (token, latitude, longitude) => {
  //     await api.getFavorite(token, latitude, longitude)
  //         .then(response => response.json())
  //         .then(result => {
  //             if (result.status === 1) {
  //                 dispatch(setFavourite({ data: result }));
  //             }
  //             else {
  //                 dispatch(setFavourite({ data: null }));
  //             }
  //         })
  //         .catch(error => console.log(error));
  // };

  // const fetchNotification = async (token) => {
  //     await api.getNotification(token)
  //         .then(response => response.json())
  //         .then(result => {
  //             if (result.status === 1) {
  //                 dispatch(setNotification({ data: result.data }));
  //                 result.total > 0 ? settotalNotification(result.total) : settotalNotification(null);
  //             }
  //         })
  //         .catch(error => console.log(error));
  // };

  // useEffect(() => {
  //     if (city.city !== null && localStorage.getItem('jwt_token') !== undefined && user.user == null) {
  //         fetchCart(localStorage.getItem('jwt_token'), city.city.latitude, city.city.longitude);
  //         // fetchFavorite(localStorage.getItem('jwt_token'), city?.city?.latitude, city?.city?.longitude);
  //         // fetchNotification(localStorage.getItem('jwt_token'));
  //     }
  // }, [user]);

  const fetchPaymentSetting = async () => {
    await api
      .getPaymentSettings(localStorage.getItem("jwt_token"))
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 1) {
          dispatch(setPaymentSetting({ data: JSON.parse(atob(result.data)) }));
        }
      })
      .catch((error) => console.log(error));
  };

  const handleChangeLanguage = (id) => {
    api
      .getSystemLanguage(id, 0)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 1) {
          document.documentElement.dir = result.data.type;
          dispatch(setLanguage({ data: result.data }));
        }
      });
  };

  const placeHolderImage = (e) => {
    e.target.src = setting.setting?.web_logo;
  };

  const handleResize = () => {
    setIsDesktopView(window.innerWidth > 768);
  };

  // console.log(isDesktopView)
  const handleMobileNavActKey = (key) => {
    setMobileNavActKey(key === mobileNavActKey ? null : key);
  };

  // const [dropdownOpen, setDropdownOpen] = useState(false);
  // const [activeCategory, setActiveCategory] = useState(null); 

  const fetchCategory = (id = 0) => {
    api
      .getCategory(id)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 1) {
          dispatch(setCategory({ data: result.data }));
          // dispatch({ type: ActionTypes.SET_CATEGORY, payload: result.data });
        }
      })
      .catch((error) => console.log("error ", error));
  };

  useEffect(() => {
    fetchCategory();
  }, []);

  const elementRef = useRef(null);

  const selectCategory = (ctg) => {
    navigate(`/category/${ctg?.slug}/${ctg?.id}`);
    dispatch(setFilterCategory({ data: ctg.id, parent_id: ctg.parent_id, categoryName: ctg.name, image_url: ctg.image_url }));
  };



  // const handleCategoryMouseEnter = (index) => {
  //   setActiveCategory(index);
  // };

  // const handleCategoryMouseLeave = () => {
  //   setActiveCategory(null);
  // };
  // Code for header fixed at top

  useEffect(() => {
    window?.addEventListener("scroll", isStickyheader);
    return () => {
      window.removeEventListener("scroll", isStickyheader);
    };
  });

  const isStickyheader = (e) => {
    const header = document.querySelector(".site-header");
    const scrollTop = window.scrollY;
    scrollTop >= 250
      ? header.classList.add("is-sticky")
      : header.classList.remove("is-sticky");
  };
  // const [categoryOpen, setCategoryOpen] = useState(null);

  // useEffect(() => {
  //   if (search.length > 0) {

  //     setShowSuggetion(true)
  //   }
  // }, [search])

  const location = useLocation();

  const [keyCheck, setKeyCheck] = useState(-1);
  // const [keyCheckArr, setKeyCheckArr] = useState([])
  // const keyPressHandler = (e) => {
  //   if (keyCheck < -1) return setKeyCheck(-1);
  //   if (keyCheck > 5) return setKeyCheck(5);

  //   // Handle arrow keys for navigation
  //   if (e.key === 'ArrowUp') {
  //     setKeyCheck((prev) => (prev > 0 ? prev - 1 : keyCheckArr.length - 1));
  //   } else if (e.key === 'ArrowDown') {
  //     setKeyCheck((prev) => (prev < keyCheckArr.slice(0, 6).length - 1 ? prev + 1 : 0));
  //   } else if (e.key === 'Enter') {
  //     // If there's a valid selection, update the search
  //     if (keyCheck >= 0) {
  //       setsearch(keyCheckArr[keyCheck]); // Set search with the selected suggestion
  //       setSuggestions(false); // Hide suggestions
  //     }
  //   }
  // };

  /* const menuRef = useRef();
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setCategoryOpen(null);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [menuRef]);
  */
  // const callImage ='./supportIcon.png';
  const searchSuggestions = (value) => {
    setShowSuggestions(true);
    setSearchValue(value);
    if (value) {
      const filteredOptions = data?.filter((option) =>
        option.toLowerCase().includes(value.toLowerCase())
      );
      const transformedOptions = filteredOptions.map((option) => ({
        value: option,
      }));
      setOptions(transformedOptions);
    } else {
      setOptions([]);
    }
  };

  const onSearch = (value) => {
    setShowSuggestions(false);
    setSearchValue(value);
    dispatch(setFilterCategory({ data: categoryObject?.id }));
    dispatch(setFilterSearch({ data: value }));
    if (value) {
      navigate("/products");
    } else if (categoryObject && suggestionCategoryId.id !== "All") {
      navigate(`/category/${categoryObject.slug}/${categoryObject.id}`);
      dispatch(setFilterCategory({ data: categoryObject.id, parent_id: categoryObject.parent_id, categoryName: categoryObject.name, mainCategory: true }));
    }
  };

  const onSelectSuggestion = (value) => {
    setSearchValue(value);
  };

  const onClearSearch = () => {
    setSearchValue('');
    dispatch(setFilterSearch({ data: '' }));
  }

  const onChangeMainCategory = (cat) => {
    setSearchValue('');
    const category = cat !== "All" ? JSON.parse(cat) : { id: cat };
    setSuggestionCategoryId(category);
    if (category !== "All") {
      setCategoryObject(category);
    }
  }

  return (
    <>
      <div
        className="hide-desktop offcanvas offcanvas-start"
        tabIndex="-1"
        id="sidebaroffcanvasExample"
        aria-labelledby="sidebaroffcanvasExampleLabel"
      >
        <div className="site-scroll ps">
          <div className="canvas-header">
            <div className="site-brand">
              <img
                src={setting.setting && setting.setting.web_settings.web_logo}
                height="70px"
                alt="logo"
              ></img>
            </div>

            <button
              type="button"
              className="close-canvas"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              ref={closeSidebarRef}
            >
              <AiOutlineCloseCircle fill="black" />
            </button>
          </div>
          <div className="canvas-main">
            <div
              className={
                isDesktopView ? "site-location " : "site-location d-none"
              }
            >
              <button
                whiletap={{ scale: 0.8 }}
                type="buton"
                onClick={openCanvasModal}
              >
                <div className="d-flex flex-row gap-2">
                  <div className="icon location p-1 m-auto">
                    <GoLocation fill="black" />
                  </div>
                  <div className="d-flex flex-column flex-grow-1">
                    <span className="location-description">
                      {t("deliver_to")} <IoMdArrowDropdown />
                    </span>
                    <span className="current-location">
                      {isLocationPresent ? (
                        <>
                          {city.status === "fulfill" ? (
                            city.city.formatted_address
                          ) : (
                            <div className="d-flex justify-content-center">
                              <div className="spinner-border" role="status">
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            </div>
                          )}
                        </>
                      ) : (
                        t("select_location")
                      )}
                    </span>
                  </div>
                </div>
              </button>
            </div>
            <nav className="canvas-menu canvas-primary vertical">
              {category.category === null ? (
                <p></p>
              ) : (
                <ul id="menu-menu-1" className="menu">
                  <li className=" menu-item menu-item-type-post_type menu-item-object-page">
                    <button
                      type="button"
                      onClick={() => {
                        closeSidebarRef.current.click();
                        navigate("/");
                      }}
                    >
                      {t("home")}
                    </button>
                  </li>
                  {category.category.map((ctg, index) => (
                    <li
                      key={index}
                      onClick={() => {
                        selectCategory(ctg);
                        closeSidebarRef.current.click();
                        window.scrollTo({ top: 0, behavior: "smooth" });
                      }}
                      className={`
											${ctg.has_child
                          ? "dropdown mega-menu menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children"
                          : "menu-item menu-item-type-post_type menu-item-object-page"
                        }`}
                    >
                      <button type="button"> {ctg.name}</button>
                      {ctg.name !== "Grocery2" && ctg.has_child && (
                        <ul
                          className="sub-menu dropdown-menu"
                          aria-labelledby="ShopDropDown"
                        >
                          {ctg.cat_active_childs.map((child) => (
                            <li
                              className="dropdown-item menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-dropdown mega-menu"
                              onClick={() => {
                                if (child.has_child) {
                                } else {
                                  selectCategory(child);
                                  closeSidebarRef.current.click();
                                  window.scrollTo({
                                    top: 0,
                                    behavior: "smooth",
                                  });
                                }
                              }}
                              key={child.id}
                            >
                              <a>{child.name}</a>
                              <IoIosArrowDown />
                              <ul
                                className="sub-menu dropdown-menu"
                                aria-labelledby="ShopDropDown"
                              >
                                {child.cat_active_childs.map((child2) => (
                                  <li
                                    className="dropdown-item menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-dropdown mega-menu"
                                    onClick={() => {
                                      selectCategory(child2);
                                      closeSidebarRef.current.click();

                                      window.scrollTo({
                                        top: 0,
                                        behavior: "smooth",
                                      });
                                    }}
                                    key={child2.id}
                                  >
                                    <a>{child2.name}</a>
                                  </li>
                                ))}
                              </ul>
                            </li>
                          ))}
                        </ul>
                      )}

                      {ctg.name === "Grocery2" && ctg.has_child && (
                        <button
                          className="menu-dropdown"
                          id="ShopDropDown"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <IoIosArrowDown />
                        </button>
                      )}
                    </li>
                  ))}
                </ul>
              )}

              <div className="lang-mode-utils">
                <div className="language-container bg-white">
                  <MdGTranslate size={24} />
                  <Dropdown>
                    <Dropdown.Toggle>
                      {languages.current_language &&
                        languages.current_language.name}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {languages.available_languages &&
                        languages.available_languages.map(
                          (language, index) => {
                            return (
                              <Dropdown.Item
                                key={index}
                                onClick={() => {
                                  handleChangeLanguage(language.id);
                                }}
                              >
                                {language.name}
                              </Dropdown.Item>
                            );
                          }
                        )}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                {/* <div className='util'>
                                    <span>Select Mode</span>
                                    <select className='' onChange={handleCssModeChange}>
                                        <option value="light">Light</option>
                                        <option value="dark">Dark</option>
                                    </select>
                                </div> */}
              </div>
            </nav>
          </div>
        </div>
      </div>

      {/* header */}
      <header className="site-header desktop-shadow-disable mobile-shadow-enable bg-white mobile-nav-enable border-bottom fixed-top mb-10">
        {/* bottom header */}
        <div className={"header-main  w-100"}>
          <div className="container-fluid" >
            <div className={`headerMargin  ${mobileNavActKey === 2 ? "" : "d-flex"} row-reverse justify-content-lg-between justify-content-between align-items-left`}>
              <div className={`d-flex ${mobileNavActKey === 2 ? "d-none" : ""} w-auto align-items-center justify-content-start col-md-2 order-1 column-left gapinhelp`}>
                <div className="header-buttons hide-desktop">
                  <button
                    className="header-canvas button-item"
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#sidebaroffcanvasExample"
                    aria-controls="sidebaroffcanvasExample"
                  >
                    <div className="button-menu">
                      <FiMenu />
                    </div>
                  </button>
                </div>

                <Link
                  to="/"
                  className={`site-brand  ${mobileNavActKey === 2 ? " searchactive" : ""}`}
                  style={
                    curr_url.pathname === "/profile"
                      ? { marginLeft: "4px" }
                      : null
                  }
                >
                  <img
                    src={
                      setting.setting && setting.setting.web_settings.web_logo
                    }
                    alt="logo"
                    className="desktop-logo hide-mobile"
                  />
                  <img
                    src={
                      setting.setting && setting.setting.web_settings.web_logo
                    }
                    alt="logo"
                    className="mobile-logo hide-desktop"
                  />
                </Link>

                <Dropdown>
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    as="div"
                    style={{ cursor: "pointer" }}
                    className={`hide-mobile helplines ${mobileNavActKey === 2 ? " searchactive" : ""}`}
                  >
                    <p>Helpline</p>
                    <img
                      src={callImage}
                      alt="Dropdown"
                      className="hide-mobile callimg "
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="hide-mobile">
                    <Dropdown.Item
                      href={`tel:${setting.setting !== null
                        ? setting.setting.support_number
                        : "number"
                        }`}
                    >
                      <img
                        src={callingimg}
                        alt="Dropdown"
                        style={{ height: "12px" }}
                      />
                      <p className="helplinecss">Helpline</p>
                    </Dropdown.Item>
                    <Dropdown.Item
                      href={`//api.whatsapp.com/send?phone=${setting.setting !== null
                        ? setting.setting.support_number
                        : "number"
                        }&text=Hello!`}
                    >
                      <img
                        src={whatsappimg}
                        alt="Dropdown"
                        style={{ height: "12px" }}
                      />
                      <p className="helplinecss">WhatsApp</p>
                    </Dropdown.Item>
                    <Dropdown.Item
                      href={`mailto:${setting.setting !== null
                        ? setting.setting.support_email
                        : "email"
                        }`}
                    >
                      <img
                        src={emailimg}
                        alt="Dropdown"
                        style={{ height: "12px" }}
                      />
                      <p className="helplinecss">Email</p>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              <div className="d-flex coustem-gap w-lg-100 col-md-7 order-2 justify-content-center align-items-center removehead">
                <button
                  whiletap={{ scale: 0.6 }}
                  type="buton"
                  className={`header-location site-location hide-mobile ${mobileNavActKey === 2 ? " searchactive" : ""} `}
                  onClick={openModal}
                >
                  <div className="d-flex flex-row gap-2">
                    <div className="icon location p-1 m-auto">
                      <GoLocation fill="black" />
                    </div>

                    <div className="d-flex flex-column flex-grow-1 align-items-start">
                      <span className="location-description">
                        {t("deliver_to")} <IoMdArrowDropdown />
                      </span>
                      <span className="current-location">
                        <>
                          {city.status === "fulfill"
                            ? city.city.formatted_address
                            : t("select_location")}
                        </>
                      </span>
                    </div>
                  </div>
                </button>
                <div
                  className={`w-100 searchBox${mobileNavActKey === 2 ? "active" : ""}`}
                >
                  <Input.Group
                    compact
                    style={{
                      display: 'flex',
                      width: '100%',
                      alignItems: 'center',
                    }}
                    className="custom-select"
                  >
                    <AntdSelect
                      defaultValue="All"
                      onChange={onChangeMainCategory}
                      style={{
                        width: 'auto',
                        borderColor: '#4caf50',
                      }}
                      popupClassName="custom-dropdown"
                      className="custom-select-border"
                    >
                      <Option value="All">All</Option>
                      {category?.category?.map((item) => (
                        <Option key={item.id} value={JSON.stringify(item)}>
                          {item.name}
                        </Option>
                      ))}
                    </AntdSelect>

                    <AutoComplete
                      options={options}
                      value={searchValue}
                      onSearch={searchSuggestions}
                      onSelect={onSelectSuggestion}
                      open={showSuggestions}
                      onClear={onClearSearch}
                      className="custom-AutoComplete"
                      allowClear
                      style={{
                        flex: '2', // Ensures it takes more space
                      }}
                    >
                      <Search
                        placeholder="What are you looking for?"
                        onSearch={(value) => {
                          onSearch(value);
                          setShowSuggestions(false);
                        }}
                        enterButton
                        size="large"
                        style={{
                          width: '100%',
                          borderColor: '#2196f3',
                          boxSizing: 'border-box',
                        }}
                        className="custom-search-button"
                      />
                    </AutoComplete>
                  </Input.Group>
                </div>
              </div>

              <div className={`col-md-3 w-auto order-3 justify-content-end align-items-center customgap ${mobileNavActKey === 2 ? "searchactive" : "d-flex"}`}>
                {/* <button type='button' whiletap={{ scale: 0.6 }} className='icon position-relative hide-mobile mx-sm-4' onClick={() => {
                                    if (localStorage.getItem('jwt_token') === undefined) {
                                        toast.error(t("required_login_message_for_notification"));
                                    }
                                    else {
                                        navigate('/notification');
                                    }
                                }}>
                                    {totalNotification === null ? <IoNotificationsOutline />
                                        : <MdNotificationsActive fill="var(--secondary-color)" />}

                                </button> */}

                {/* {city.city === null || localStorage.getItem('jwt_token') === undefined
                                    ? <button whiletap={{ scale: 0.6 }} className='icon mx-sm-4 position-relative hide-mobile-screen'
                                        onClick={() => {
                                            if (localStorage.getItem('jwt_token') === undefined) {
                                                toast.error(t("required_login_message_for_cartRedirect"));
                                            }
                                            else if (city.city === null) {
                                                toast.error("Please Select you delivery location first!");
                                            }
                                        }}>
                                        <IoHeartOutline className='' />
                                    </button>
                                    : <button whiletap={{ scale: 0.6 }} className='icon mx-4 position-relative hide-mobile-screen'
                                        onClick={() => {
                                            if (localStorage.getItem('jwt_token') === undefined) {
                                                toast.error(t("required_login_message_for_cartRedirect"));
                                            }
                                            else if (city.city === null) {
                                                toast.error("Please Select you delivery location first!");
                                            } else {
                                                navigate("/wishlist");
                                            }
                                        }}>
                                        <IoHeartOutline className='' />
                                        {(favorite.favouritelength !== 0) ?
                                            <span className="position-absolute start-100 translate-middle badge rounded-pill fs-5 ">
                                                {favorite.favouritelength}
                                                <span className="visually-hidden">unread messages</span>
                                            </span>
                                            : null}

                                    </button>
                                } */}

                {/* login option */}
                {user.status === "loading" ? (
                  <div className="hide-mobile-screen">
                    <div
                      whiletap={{ scale: 0.6 }}
                      className="d-flex flex-row user-profile gap-1 pt-0"
                      id="user-profile"
                      onClick={() => setShowModal(true)}
                    >
                      <div className="d-flex align-items-center user-info my-auto">
                        <span className="btn-success">
                          <RiAccountCircleFill className="user_icon" />
                        </span>
                        <span className="pe-3">{t("login")}</span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="hide-mobile-screen ms-4">
                    <Link
                      to="/profile"
                      className="d-flex align-items-center flex-row user-profile gap-1"
                    >
                      <img
                        onError={placeHolderImage}
                        src={user.user && user.user.profile}
                        alt="user"
                      ></img>
                      <div className="d-flex flex-column user-info my-auto">
                        <span className="number"> {t("welcome")}</span>
                        <span className="name">
                          {" "}
                          {user.user && user.user.name.split(" ")[0]}
                        </span>
                      </div>
                    </Link>
                  </div>
                )}
                {/* Login option end */}

                {curr_url.pathname === "/checkout" ? null : city.city ===
                  null || localStorage.getItem("jwt_token") === undefined ? (
                  <>
                    <button
                      type="button"
                      className={isDesktopView ? "d-none" : "d-block mt-2"}
                      onClick={openCanvasModal}
                    >
                      <GoLocation
                        size={25}
                        style={{
                          backgroundColor: `var(--second-cards-color)`,
                          marginBottom: "4px",
                        }}
                      />
                    </button>
                    <button
                      type="button"
                      whiletap={{ scale: 0.6 }}
                      className="icon mx-0 me-sm-0 position-relative  "
                      onClick={() => {
                        if (localStorage.getItem("jwt_token") === undefined) {
                          toast.error(
                            t("required_login_message_for_cartRedirect")
                          );
                        } else if (city.city === null) {
                          toast.error(
                            "Please Select you delivery location first!"
                          );
                        }
                      }}
                    >
                      <IoCartOutline />
                      <p className="cartname hide-desktop1">Cart</p>
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      type="button"
                      className={isDesktopView ? "d-none" : "d-block mt-2"}
                      onClick={openCanvasModal}
                    >
                      <GoLocation
                        size={25}
                        style={{
                          backgroundColor: `var(--second-cards-color)`,
                          marginBottom: "4px",
                        }}
                      />
                    </button>
                    <div
                      type="button"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#cartoffcanvasExample"
                      aria-controls="cartoffcanvasExample"
                      className="cart-button-container"
                      style={{
                        position: 'relative',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                        paddingLeft: '10px'
                      }}
                      onClick={() => {
                        if (localStorage.getItem("jwt_token") === undefined) {
                          toast.error(
                            t("required_login_message_for_cartRedirect")
                          );
                        } else if (city.city === null) {
                          toast.error(
                            "Please Select you delivery location first!"
                          );
                        } else {
                          setIsCartSidebarOpen(true);
                        }
                      }}
                    >
                      <div
                        className="icon-container"
                        style={{
                          position: 'relative',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                        }}
                      >
                        <IoCartOutline style={{ fontSize: '3rem' }} />

                        {(localStorage.getItem('jwt_token')) && cart?.cartProducts?.length !== 0 && (
                          <span
                            className="badge"
                            style={{
                              position: 'absolute',
                              top: '-13px',
                              right: '-10px',
                              backgroundColor: '#c40c0c',
                              color: '#fff',
                              fontSize: '12px',
                              padding: '3px 8px',
                              borderRadius: '50%',
                            }}
                          >
                            {cart.cartProducts.length}
                          </span>
                        )}
                      </div>
                      <p
                        className="cartname"
                        style={{
                          fontSize: '1.5rem',
                          marginBottom: '0',
                          marginRight: '15px'
                        }}
                      >
                        Cart
                      </p>
                    </div>
                  </>
                )}
                <p className="registerseller">
                  <Link to="/landingpage" className="sellerlink">
                    Become a Seller
                  </Link>
                </p>
              </div>
            </div>

            {/* {showSuggetion ? (
                <Suggestion
                  setShowSuggetion={setShowSuggetion}
                  search={search}
                  setsearch={setsearch}
                  suggestions={suggestions}
                  setSuggestions={setSuggestions}
                  data={data}
                  setData={setData}
                  setFilterSearch={setFilterSearch}
                  setFilterCategory={setFilterCategory}
                  setFilterBrands={setFilterBrands}
                  setFilterMinMaxPrice={setFilterMinMaxPrice}
                  dispatch={dispatch}
                  searchNavTrigger={searchNavTrigger}
                  navigate={navigate}
                  curr_url={curr_url}
                  keyCheck={keyCheck}
                  setKeyCheck={setKeyCheck}

                  setKeyCheckArr={setKeyCheckArr}
                />
              ) : (
                ""
              )} */}
            {/* code for getting categories in header */}
          </div>
        </div>
        <div className="container displaynone d-flex flex-wrap" hide-mobile-screen>
          <ProductCategory
            category={category}
            selectCategory={selectCategory}
            navigate={navigate}
            setFilterCategory={setFilterCategory}
          />
        </div>

        {/* Mobile bottom Nav */}
        <nav className="header-mobile-nav">
          <div className="mobile-nav-wrapper">
            <ul>
              <li className="menu-item">
                <Link
                  to="/products"
                  className={`shop ${curr_url.pathname === "/products" && mobileNavActKey === 1
                    ? "active"
                    : ""
                    }`}
                  onClick={() => {
                    handleMobileNavActKey(1);
                  }}
                >
                  <div style={{ marginBottom: "-7px" }}>
                    <BsShopWindow fill="black" />
                  </div>
                  <span>{t("shop")}</span>
                </Link>
              </li>

              <li className="menu-item">
                <button
                  type="button"
                  className={`search ${mobileNavActKey === 2 ? "active" : ""
                    }`}
                  ref={searchNavTrigger}
                  onClick={() => {
                    handleMobileNavActKey(2);
                    searchNavTrigger.current.focus();
                  }}
                >
                  <div style={{ marginBottom: "-7px" }}>
                    <MdSearch />
                  </div>
                  <span>{t("search")}</span>
                </button>
              </li>

              {/* {curr_url.pathname === "/products" ? (
                  <li className="menu-item">
                    <button
                      type="button"
                      className={`filter ${mobileNavActKey === 3 ? "active" : ""
                        }`}
                      data-bs-toggle="offcanvas"
                      data-bs-target="#filteroffcanvasExample"
                      aria-controls="filteroffcanvasExample"
                      onClick={() => {
                        handleMobileNavActKey(3);
                      }}
                    >
                      <div>
                        <FiFilter />
                      </div>
                      <span>{t("filter")}</span>
                    </button>
                  </li>
                ) : (
                  ""
                )} */}

              <li className="menu-item">
                {city.city === null ||
                  localStorage.getItem("jwt_token") === undefined ? (
                  <button
                    type="button"
                    className={`wishlist ${mobileNavActKey === 4 ? "active" : ""
                      }`}
                    onClick={() => {
                      if (localStorage.getItem("jwt_token") === undefined) {
                        toast.error(t("required_login_message_for_wishlist"));
                      } else if (city.city === null) {
                        toast.error(
                          "Please Select you delivery location first!"
                        );
                      } else {
                        handleMobileNavActKey(4);
                        navigate("/wishlist");
                      }
                    }}
                  >
                    <div style={{ marginBottom: "-7px" }}>
                      <IoHeartOutline />
                    </div>
                    <span>{t("wishList")}</span>
                  </button>
                ) : (
                  <button
                    type="button"
                    className={`wishlist ${mobileNavActKey === 4 ? "active" : ""
                      }`}
                    onClick={() => {
                      if (localStorage.getItem("jwt_token") === undefined) {
                        toast.error(
                          t("required_login_message_for_cartRedirect")
                        );
                      } else if (city.city === null) {
                        toast.error(
                          "Please Select you delivery location first!"
                        );
                      } else {
                        handleMobileNavActKey(4);
                        navigate("/wishlist");
                      }
                    }}
                  >
                    {/*  data-bs-toggle="offcanvas" data-bs-target="#favoriteoffcanvasExample" aria-controls="favoriteoffcanvasExample" */}
                    <div style={{ marginBottom: "-7px" }}>
                      <IoHeartOutline />

                      {favorite.favorite && favorite.favorite.status !== 0 ? (
                        <span
                          className="translate-middle badge rounded-pill fs-5"
                          style={{
                            background: "var(--secondary-color)",
                            borderRadius: "50%",
                            color: "#fff",
                            top: "1px",
                            right: "-9px",
                          }}
                        >
                          {favorite.favorite &&
                            favorite.favorite.status !== 0 &&
                            favorite.favorite.total}
                          <span className="visually-hidden">
                            unread messages
                          </span>
                        </span>
                      ) : null}
                    </div>
                    <span>{t("wishList")}</span>
                  </button>
                )}
              </li>
              {curr_url.pathname === "/profile" ? (
                <li className="menu-item">
                  <button
                    type="button"
                    className={`profile-account user-profile ${mobileNavActKey === 5 ? "active" : ""
                      }`}
                    onClick={() => {
                      handleMobileNavActKey(5);
                      document
                        .getElementsByClassName("profile-account")[0]
                        .classList.toggle("active");
                      document
                        .getElementsByClassName("wishlist")[0]
                        .classList.remove("active");
                      if (curr_url.pathname === "/products") {
                        document
                          .getElementsByClassName("filter")[0]
                          .classList.remove("active");
                      }
                      if (curr_url.pathname !== "/products") {
                        document
                          .getElementsByClassName("shop")[0]
                          .classList.remove("active");
                      }
                      document
                        .getElementsByClassName("search")[0]
                        .classList.remove("active");
                      document
                        .getElementsByClassName("header-search")[0]
                        .classList.remove("active");
                    }}
                    data-bs-toggle="offcanvas"
                    data-bs-target="#profilenavoffcanvasExample"
                    aria-controls="profilenavoffcanvasExample"
                  >
                    {/* <div>
                                            <MdOutlineAccountCircle />
                        
                                        </div> */}
                    <div style={{ marginBottom: "-7px" }}>
                      <img src={user?.user?.profile} alt="profile_image" />
                    </div>
                    <span>{t("my_account")}</span>
                  </button>
                </li>
              ) : (
                <li className="menu-item">
                  {user.status === "loading" ? (
                    <>
                      <button
                        type="button"
                        className={`account ${mobileNavActKey === 5 ? "active" : ""
                          }`}
                        // data-bs-toggle="modal" data-bs-target="#loginModal"
                        onClick={() => {
                          setShowModal(true);
                          handleMobileNavActKey(5);
                        }}
                      >
                        <div >
                          <BiUserCircle />
                        </div>
                        <span>{t("login")}</span>
                      </button>
                    </>
                  ) : (
                    <>
                      <Link
                        to="/profile"
                        className={`d-flex user-profile account ${mobileNavActKey === 5 ? "active" : ""
                          }`}
                        onClick={() => {
                          handleMobileNavActKey(5);
                        }}
                      >
                        <div className="d-flex flex-column user-info my-auto">
                          <span className="name"> {user.user?.name}</span>
                        </div>
                        <img
                          onError={placeHolderImage}
                          src={user.user?.profile}
                          alt="user"
                        ></img>
                        <span>{t("profile")}</span>
                      </Link>
                    </>
                  )}
                </li>
              )}
            </ul>
          </div>
        </nav>

        {/* login modal */}

        <Login show={showModal} setShow={setShowModal} />

        {/* location modal */}
        <Modal
          className="location"
          id="locationModal"
          centered
          open={locModal}
          transitionName=""
        >
          <Location
            isLocationPresent={isLocationPresent}
            setisLocationPresent={setisLocationPresent}
            showModal={locModal}
            setLocModal={setLocModal}
            bodyScroll={setBodyScroll}
          />
        </Modal>

        {/* Cart Sidebar */}
        <Cart
          isCartSidebarOpen={isCartSidebarOpen}
          setIsCartSidebarOpen={setIsCartSidebarOpen}
        />
      </header >
    </>
  );
}
export default Header;
