import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/messaging";
import { useSelector } from "react-redux";
import Loader from "../../components/loader/Loader";

const FirebaseData = () => {
  const setting = useSelector((state) => state.setting);

  if (setting.setting === null) {
    return <Loader screen="full" />;
  }

  const {
    apiKey,
    authDomain,
    projectId,
    storageBucket,
    messagingSenderId,
    appId,
    measurementId,
  } = setting.setting.firebase;

  const firebaseConfig = {
    apiKey,
    authDomain,
    projectId,
    storageBucket,
    messagingSenderId,
    appId,
    measurementId,
  };

  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  } else {
    firebase.app();
  }

  const auth = firebase.auth();
  let messaging;

  if (firebase.messaging.isSupported()) {
    messaging = firebase.messaging();

    try {
      messaging.onMessage((payload) => {
        const data = payload?.data;
        new Notification(data?.title, {
          body: data?.message,
          icon: data?.image || setting?.setting?.web_settings?.web_logo,
        });
      });
    } catch (err) {
      console.error("Messaging error:", err.message);
    }
  } else {
    console.warn("Firebase Messaging is not supported in this environment.");
  }

  return { auth, firebase, messaging };
};

export default FirebaseData;
