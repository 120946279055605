import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    status: "loading",
    categories: [],
};

export const categoryByIdReducer = createSlice({
    name: "categories",
    initialState,
    reducers: {
        setCategoryById: (state, action) => {
            state.status = "fulfill";
            state.categories = action.payload.data;
        },
    }
});

export const { setCategoryById } = categoryByIdReducer.actions;
export default categoryByIdReducer.reducer;