import React, { useEffect, useState } from 'react';
import styles from './QuickViewModel.module.css';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { BsHeart, BsPlus, BsHeartFill } from "react-icons/bs";
import { BiMinus, BiLink } from 'react-icons/bi';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { toast } from 'react-toastify';
import api from '../../api/api';
import Cookies from 'universal-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { FacebookIcon, FacebookShareButton, TelegramIcon, TelegramShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share';
import Loader from '../loader/Loader';
import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
// import { AddToCart, AddToFavorite, RemoveFromCart, RemoveFromFavorite } from '../../functions/Functions';
import { setProductSizes } from '../../model/reducer/productSizesReducer';
import { setCart, setCartProducts, setCartSubTotal, setSellerFlag } from '../../model/reducer/cartReducer';
import { setFavourite, setFavouriteLength, setFavouriteProductIds } from '../../model/reducer/favouriteReducer';
import Popup from '../same-seller-popup/Popup';


const QuickViewModal = (props) => {
    const setting = useSelector(state => state.setting);


    const cookies = new Cookies();
    const dispatch = useDispatch();

    const city = useSelector(state => state.city);
    const sizes = useSelector(state => state.productSizes);

    const cart = useSelector(state => state.cart);
    const favorite = useSelector(state => state.favourite);
    const user = useSelector(state => state.user);



    const [mainimage, setmainimage] = useState("");
    const [productcategory, setproductcategory] = useState({});
    // const [productbrand, setproductbrand] = useState({});
    const [product, setproduct] = useState({});
    const [productSizes, setproductSizes] = useState(null);
    const [isLoader, setisLoader] = useState(false);
    const [variant_index, setVariantIndex] = useState(null);
    const [selectedVariant, setSelectedVariant] = useState(null);
    const [quantity, setQuantity] = useState(0);

    useEffect(() => {
        if (Object.keys(props.selectedProduct).length > 0 && city.city !== null && Object.keys(product).length === 0 && props.showModal === true) {
            // getCategoryDetails();
            // getBrandDetails();
            fetchProduct(props.selectedProduct.id);
        }
        else if (props.showModal === true) {
            fetchProductVariant(props.selectedProduct.id);
        }
    }, [props.selectedProduct, city]);



    const fetchProduct = async (product_id) => {
        // console.log("fetchProduct Called");
        await api.getProductbyId(city.city.latitude, city.city.longitude, product_id, localStorage.getItem('jwt_token'))
            .then(response => response.json())
            .then(result => {
                if (result.status === 1) {
                    // console.log("fetchProduct Result ->", result.data);
                    setproduct(result.data);
                    setVariantIndex(result.data.variants?.length > 0 && result.data.variants[0]?.id);
                    setmainimage(result.data.image_url);
                    setSelectedVariant((result.data.variants?.length > 0 && result.data.variants.find((element) => element.id == variant_index)) || result.data.variants?.[0]);
                }
            })
            .catch(error => console.log(error));
    };

    const fetchProductVariant = async (product_id) => {
        await api.getProductbyId(city.city.latitude, city.city.longitude, product_id, localStorage.getItem('jwt_token'))
            .then(response => response.json())
            .then(result => {
                if (result.status === 1) {
                    setproduct(result.data);
                    setVariantIndex(result.data.variants?.length > 0 && result.data.variants[0]?.id);
                    setmainimage(result.data.image_url);
                    setSelectedVariant((result.data.variants?.length > 0 && result.data.variants.find((element) => element.id == variant_index)) || result.data.variants?.[0]);
                }
            })
            .catch(error => console.log(error));
    };



    useEffect(() => {
        if (sizes.sizes === null || sizes.status === 'loading') {
            if (city.city !== null) {
                api.getProductbyFilter(city.city.latitude, city.city.longitude, localStorage.getItem('jwt_token'))
                    .then(response => response.json())
                    .then(result => {
                        if (result.status === 1) {
                            setproductSizes(result.sizes);
                            dispatch(setProductSizes({ data: result.sizes }));
                        }
                    });
            }
        }
        else {
            setproductSizes(sizes.sizes);
        }
    }, [city, sizes]);




    // const getCategoryDetails = () => {
    //     api.getCategory()
    //         .then(response => response.json())
    //         .then(result => {
    //             if (result.status === 1) {
    //                 result.data.forEach(ctg => {
    //                     if (ctg.id === props.selectedProduct.category_id) {
    //                         setproductcategory(ctg);
    //                     }
    //                 });
    //             }
    //         })
    //         .catch((error) => console.log(error));
    // };

    // const getBrandDetails = () => {
    //     api.getBrands()
    //         .then(response => response.json())
    //         .then(result => {
    //             if (result.status === 1) {
    //                 result.data.forEach(brnd => {
    //                     if (brnd.id === props.selectedProduct.brand_id) {
    //                         setproductbrand(brnd);
    //                     }
    //                 });
    //             }
    //         })
    //         .catch((error) => console.log(error));
    // };


    //Add to Cart
    const addtoCart = async (product_id, product_variant_id, qty) => {
        // console.log("QuickView Add to Cart -> ", product_id, product_variant_id, qty);
        setisLoader(true);
        await api.addToCart(localStorage.getItem('jwt_token'), product_id, product_variant_id, qty)
            .then(response => response.json())
            .then(async (result) => {
                if (result.status === 1) {
                    //  toast.success(result.message);
                    if (cart?.cartProducts?.find((product) => (product?.product_id == product_id) && (product?.product_variant_id == product_variant_id))?.qty == undefined) {
                        dispatch(setCart({ data: result }));
                        dispatch(setCartSubTotal({ data: result?.data?.sub_total }));
                        const updatedCartCount = [...cart?.cartProducts, { product_id: product_id, product_variant_id: product_variant_id, qty: qty }];
                        dispatch(setCartProducts({ data: updatedCartCount }));
                    } else {
                        const updatedProducts = cart?.cartProducts?.map(product => {
                            if ((product.product_id == product_id) && (product?.product_variant_id == product_variant_id)) {
                                return { ...product, qty: qty };
                            } else {
                                return product;
                            }
                        });
                        dispatch(setCart({ data: result }));
                        dispatch(setCartProducts({ data: updatedProducts }));
                        dispatch(setCartSubTotal({ data: result?.data?.sub_total }));
                    }
                }
                else if (result?.data?.one_seller_error_code == 1) {
                    dispatch(setSellerFlag({ data: 1 }));
                    // console.log(result.message);
                    // toast.error(t(`${result.message}`));
                    props.setShowModal(false);
                } else {
                    //   toast.error(result.message);
                }
                setisLoader(false);
            });
    };

    //remove from Cart
    const removefromCart = async (product_id, product_variant_id) => {
        await api.removeFromCart(localStorage.getItem('jwt_token'), product_id, product_variant_id)
            .then(response => response.json())
            .then(async (result) => {
                if (result.status === 1) {
                    toast.success(result.message);
                    dispatch(setCartSubTotal({ data: result?.sub_total }));
                    const updatedCartProducts = cart?.cartProducts?.filter(product => {
                        if (product?.product_variant_id != product_variant_id) {
                            return product;
                        }
                    });
                    dispatch(setCartProducts({ data: updatedCartProducts ? updatedCartProducts : [] }));
                }
                else {
                    toast.error(result.message);
                }
            });
    };

    //Add to favorite
    const addToFavorite = async (product_id) => {
        await api.addToFavotite(localStorage.getItem('jwt_token'), product_id)
            .then(response => response.json())
            .then(async (result) => {
                if (result.status === 1) {
                    toast.success(result.message);
                    const updatedFavouriteProducts = [...favorite.favouriteProductIds, product_id];
                    dispatch(setFavouriteProductIds({ data: updatedFavouriteProducts }));
                    const updatedFavouriteLength = favorite?.favouritelength + 1;
                    dispatch(setFavouriteLength({ data: updatedFavouriteLength }));
                }
                else {
                    // setisLoader(false);
                    toast.error(result.message);
                }
            });
    };

    const removefromFavorite = async (product_id) => {
        await api.removeFromFavorite(localStorage.getItem('jwt_token'), product_id)
            .then(response => response.json())
            .then(async (result) => {
                if (result.status === 1) {
                    toast.success(result.message);
                    const updatedFavouriteProducts = favorite?.favouriteProductIds.filter(id => id != product_id);
                    dispatch(setFavouriteProductIds({ data: updatedFavouriteProducts }));
                    const updatedFavouriteLength = favorite?.favouritelength - 1;
                    dispatch(setFavouriteLength({ data: updatedFavouriteLength }));
                }
                else {
                    toast.error(result.message);
                }
            });
    };

    const settings_subImage = {
        infinite: false,
        slidesToShow: 3,
        initialSlide: 0,
        // centerMargin: "10px",
        //         margin: "20px",
        rows: 1,
        prevArrow: (
            <button
                type="button"
                className={styles.slickprev}
                onClick={(e) => {
                    setmainimage(e.target.value);
                }}
            >
                <FaChevronLeft fill='black' size={30} className={styles.prevarrow} />
            </button>
        ),
        nextArrow: (
            <button
                type="button"
                className={styles.slicknext}
                onClick={(e) => {
                    setmainimage(e.target.value);
                }}
            >
                <FaChevronRight fill='black' size={30} className={styles.nextarrow} />
            </button>
        ),
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const handleVariantChange = (variant, index) => {
        // console.log(variant, index);
        props.setP_id(product.id);
        props.setP_V_id(variant.id);
        setSelectedVariant(variant);
        setVariantIndex(index);
    };

    const { t } = useTranslation();

    const placeHolderImage = (e) => {

        e.target.src = setting.setting?.web_logo;
    };

    function getProductQuantities(products) {
        return Object.entries(products.reduce((quantities, product) => {
            const existingQty = quantities[product.product_id] || 0;
            return { ...quantities, [product.product_id]: existingQty + product.qty };
        }, {})).map(([productId, qty]) => ({
            product_id: parseInt(productId),
            qty
        }));
    }

    return (
        <Modal
            size='lg'
            centered
            show={props.showModal}
            onHide={() => props.setShowModal(false)}
            backdrop={"static"}>
            <div className={styles.productdetailsview}>
                <Modal.Body className='modal-body'>
                    <div className="d-flex flex-row justify-content-end header">
                        <button type="button" aria-label="Close" onClick={() => {
                            props.setselectedProduct({});
                            // setproductcategory({});
                            // setproductbrand({});
                            setproduct({});
                            setSelectedVariant(null);
                            setQuantity(0);
                            setVariantIndex(0);
                            props.setShowModal(false);
                        }} className="bg-white"><AiOutlineCloseCircle size={30} /></button>
                    </div>

                    {
                        Object.keys(product).length === 0 || productSizes === null

                            ? (
                                < Loader />

                            )
                            : (
                                <div className={`${styles.topwrapper} top-wrapper`}>

                                    <div className={`row ${styles.bodywrapper}`}>
                                        <div className="col-xl-4 col-lg-6 col-md-12 col-12">
                                            <div className={`${styles.imagewrapper} image-wrapper`}>
                                                <div className={`${styles.mainimage} border`}>
                                                    <img onError={placeHolderImage} src={mainimage} alt='main-product' className='col-12' />
                                                </div>


                                                <div className={` ${styles.subimagescontainer} sub-images-container row`}>
                                                    {product.images.length >= 1 ?
                                                        <Slider  {...settings_subImage}>
                                                            {product.images.map((image, index) => (
                                                                <div key={index} className={`${styles.subimage} ${mainimage === image ? styles.active : ''}`.trim()}>
                                                                    <img onError={placeHolderImage} src={image} className='col-12 w-100' alt="product" onClick={() => {
                                                                        setmainimage(image);
                                                                    }}></img>
                                                                </div>
                                                            ))}
                                                        </Slider>
                                                        :
                                                        <>
                                                            {product.images.map((image, index) => (
                                                                <div key={index} className={`${styles.subimage} ${mainimage === image ? styles.active : ''}`.trim()}>
                                                                    <img onError={placeHolderImage} src={image} className='col-12 ' alt="product" onClick={() => {
                                                                        setmainimage(image);
                                                                    }}></img>
                                                                </div>
                                                            ))}
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-8 col-lg-6 col-md-12 col-12">

                                            <div className={`${styles.detailwrapper} detail-wrapper`}>
                                                <div className={`${styles.topsection} top-section`}>
                                                    <p className={styles.product_name}>{product.name}</p>
                                                    <div className="d-flex flex-row gap-2 align-items-center my-1">

                                                        <div className={`${styles.pricesectionquickview} d-flex flex-row gap-2 align-items-center my-1`}>
                                                            {setting.setting && setting.setting.currency}
                                                            <p className={`${styles.priceContainer} m-0`}>
                                                                {selectedVariant ? (selectedVariant.discounted_price === 0 ? selectedVariant.price?.toFixed(setting.setting && setting.setting.decimal_point) : selectedVariant.discounted_price?.toFixed(setting.setting && setting.setting.decimal_point)) : (product.variants[0].discounted_price === 0 ? product.variants[0].price?.toFixed(setting.setting && setting.setting.decimal_point) : product.variants[0].discounted_price?.toFixed(setting.setting && setting.setting.decimal_point))}
                                                            </p>
                                                            {(selectedVariant?.price && (selectedVariant?.discounted_price !== 0)) && (selectedVariant?.price !== selectedVariant?.discounted_price) ?
                                                                <p className='fw-normal text-decoration-line-through' style={{ color: "var(--sub-text-color)", fontSize: "16px", marginTop: "5px" }}>
                                                                    {setting.setting && setting.setting.currency}
                                                                    {selectedVariant?.price?.toFixed(setting.setting && setting.setting.decimal_point)}
                                                                </p>
                                                                : null}
                                                        </div>
                                                    </div>




                                                </div>
                                                <div className={styles.bottomsection}>
                                                    {/* <p>{t("product_variants")}</p> */}


                                                    <div className={`d-flex gap-3 ${styles.bottomsectioncontent} bottom-section-content`}>
                                                        <input type="hidden" id="productdetail-selected-variant-id" name="variant" value={selectedVariant ? selectedVariant.id : product.variants[0].id} />
                                                        <div className="variants">
                                                            <div className={`row ${styles.rowVariants}`}>
                                                                {/* <input type="hidden" name="" value={product.variants[0].id} id='quickview-selected-variant-id' /> */}
                                                                {product.variants.map((variant, index) => {
                                                                    return (
                                                                        <div className={`${styles.variantsection} col-2`} key={variant?.id}>
                                                                            <div className={`${styles.variantelement} ${variant_index === variant.id ? styles.active : ''} ${Number(product.is_unlimited_stock) ? "" : (!variant.status ? styles.out_of_stock : "")}`} key={index}>
                                                                                <label className={styles.element_container} htmlFor={`variants${index}`}>
                                                                                    <div className={styles.topsectionvariant}>

                                                                                        <input type="radio" name={`variants${index}`} id={`variants${index}`} checked={variant_index === variant.id} disabled={Number(product.is_unlimited_stock) ? false : (variant.cart_count >= variant.stock ? true : false)} onChange={() => handleVariantChange(variant, variant.id)
                                                                                        }
                                                                                        />
                                                                                    </div>
                                                                                    <div className={`${styles.bottomsection} bottom-section`}>
                                                                                        <span className={`d-flex align-items-center flex-column ${styles.clothingSize}`}>{variant.measurement} {variant.stock_unit_name} </span>
                                                                                    </div>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>




                                                    </div>
                                                    {selectedVariant ? (user?.user && cart?.cartProducts?.find(prdct => prdct?.product_variant_id == selectedVariant.id)?.qty >= 1 ? <>
                                                        <div id={`input-cart-quickview`} className={styles.inputtocart}>
                                                            {/* Remove From Cart Button */}
                                                            <button type='button' onClick={(e) => {
                                                                e.preventDefault();
                                                                if (cart?.cartProducts?.find(prdct => prdct?.product_variant_id == selectedVariant.id)?.qty == 1) {
                                                                    removefromCart(product.id, selectedVariant.id);
                                                                }
                                                                else {
                                                                    addtoCart(product.id, selectedVariant.id, cart?.cartProducts?.find(prdct => prdct?.product_variant_id == selectedVariant.id)?.qty - 1);

                                                                }

                                                            }} className={styles.wishlistbutton}>
                                                                <BiMinus fill='#fff' />
                                                            </button>
                                                            <span id={`input-quickview`} >{cart?.cartProducts?.find(prdct => prdct?.product_variant_id == selectedVariant.id)?.qty}</span>
                                                            <button type='button' onClick={(e) => {
                                                                e.preventDefault();
                                                                const productQuantity = getProductQuantities(cart?.cartProducts);
                                                                if (Number(product.is_unlimited_stock)) {
                                                                    if (productQuantity?.find(prdct => prdct?.product_id == product?.id)?.qty >= Number(product?.total_allowed_quantity)) {
                                                                        toast.error('Apologies, maximum product quantity limit reached');
                                                                    }
                                                                    else {
                                                                        addtoCart(product.id, selectedVariant.id, cart?.cartProducts?.find(prdct => prdct?.product_variant_id == selectedVariant.id)?.qty + 1);
                                                                    }
                                                                }
                                                                else {

                                                                    if (selectedVariant.cart_count >= Number(selectedVariant.stock)) {
                                                                        toast.error('Oops, Limited Stock Available');
                                                                    }
                                                                    else if (productQuantity?.find(prdct => prdct?.product_id == product?.id)?.qty >= Number(product?.total_allowed_quantity)) {
                                                                        toast.error('Apologies, maximum cart quantity limit reached');
                                                                    }
                                                                    else {
                                                                        addtoCart(product.id, selectedVariant.id, cart?.cartProducts?.find(prdct => prdct?.product_variant_id == selectedVariant.id)?.qty + 1);

                                                                    }
                                                                }

                                                            }} className={styles.wishlistbutton}><BsPlus fill='#fff' /> </button>


                                                        </div>
                                                    </> : <>
                                                        <button type='button' id={`Add-to-cart-quickview`} className={styles.Addtocartquickview}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                const productQuantity = getProductQuantities(cart?.cartProducts);
                                                                if (localStorage.getItem('jwt_token') !== undefined) {
                                                                    if (productQuantity?.find(prdct => prdct?.product_id == product?.id)?.qty >= Number(product?.total_allowed_quantity)) {
                                                                        toast.error('Oops, Limited Stock Available');
                                                                    }
                                                                    else if (Number(product.is_unlimited_stock)) {
                                                                        addtoCart(product.id, selectedVariant.id, 1);
                                                                    } else {
                                                                        if (selectedVariant.status) {
                                                                            addtoCart(product.id, selectedVariant.id, 1);
                                                                        } else {
                                                                            toast.error('Oops, Limited Stock Available');
                                                                        }
                                                                    }
                                                                }
                                                                else {
                                                                    toast.error(t("required_login_message_for_cartRedirect"));
                                                                }
                                                            }}>{t("add_to_cart")}</button>
                                                    </>)
                                                        : product.variants[0].cart_count >= 1 ? <>
                                                            <div id={`input-cart-quickview`} className={styles.inputtocart}>
                                                                <button type='button' onClick={(e) => {
                                                                    e.preventDefault();
                                                                    if (product.variants[0].cart_count === 1) {

                                                                        removefromCart(product.id, product.variants[0].id);
                                                                    }
                                                                    else {
                                                                        addtoCart(product.id, product.variants[0].id, product.variants[0].cart_count - 1);
                                                                        setQuantity(quantity - 1);
                                                                    }

                                                                }} className={styles.wishlistbutton}><BiMinus fill='#fff' /></button>
                                                                <span id={`input-quickview`} >{product.variants[0].cart_count}</span>
                                                                <button type='button' onClick={(e) => {
                                                                    e.preventDefault();
                                                                    if (Number(product.is_unlimited_stock)) {
                                                                        if (product.variants[0].cart_count >= Number(setting.setting.max_cart_items_count)) {
                                                                            toast.error('Apologies, maximum product quantity limit reached');
                                                                        }

                                                                        else {
                                                                            addtoCart(product.id, product.variants[0].id, product.variants[0].cart_count + 1);

                                                                        }
                                                                    }
                                                                    else {

                                                                        if (product.variants[0].cart_count >= Number(setting.setting.max_cart_items_count)) {
                                                                            toast.error('Apologies, maximum product quantity limit reached');
                                                                        }
                                                                        else if (product.variants[0].cart_count >= Number(product.variants[0].stock)) {
                                                                            toast.error('Oops, Limited Stock Available');
                                                                        }
                                                                        else {
                                                                            addtoCart(product.id, product.variants[0].id, product.variants[0].cart_count + 1);

                                                                        }
                                                                    }

                                                                }} className={styles.wishlistbutton}><BsPlus fill='#fff' /> </button>


                                                            </div>
                                                        </> : <>


                                                            <button type='button' id={`Add-to-cart-quickview`} className={styles.Addtocartquickview}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    if (localStorage.getItem('jwt_token') !== undefined) {

                                                                        if (cart.cart && cart.cart.data.cart?.some(element => element.product_variant_id === product.variants.find((variant) => variant.id == variant_index))) {
                                                                            toast.info('Product already in Cart');
                                                                        } else {
                                                                            if (product.variants[0].status) {

                                                                                addtoCart(product.id, product.variants[0].id, 1);
                                                                            } else {
                                                                                toast.error(t("out_of_stock_message"));
                                                                            }
                                                                        }
                                                                    }
                                                                    else {
                                                                        toast.error(t("required_login_message_for_cartRedirect"));
                                                                    }
                                                                }}>{t("add_to_cart")}</button>

                                                        </>
                                                    }

                                                    {favorite.favorite && favorite.favouriteProductIds?.some(id => id == product.id) ? (
                                                        <button type="button" className={styles.wishlistproduct} onClick={() => {
                                                            if (localStorage.getItem('jwt_token') !== undefined) {
                                                                removefromFavorite(product.id);
                                                            } else {
                                                                toast.error(t('required_login_message_for_cart'));
                                                            }
                                                        }}
                                                        >
                                                            <BsHeartFill size={16} fill='green' />
                                                        </button>
                                                    ) : (
                                                        <button key={product.id} type="button" className={styles.wishlistproduct} onClick={() => {
                                                            if (localStorage.getItem('jwt_token') !== undefined) {
                                                                addToFavorite(product.id);
                                                            } else {
                                                                toast.error(t("required_login_message_for_cart"));
                                                            }
                                                        }}>
                                                            <BsHeart size={16} /></button>
                                                    )}

                                                    {product?.fssai_lic_no &&
                                                        <div className={styles.fssaidetails}>
                                                            <div className={`${styles.imagecontainer} image-container`}>
                                                                <img src={product?.fssai_lic_img} alt='fssai' />
                                                            </div>
                                                            <div className={`${styles.fssailicenseno} fssai-license-no`}>
                                                                <span>
                                                                    {`${t('license_no')} : ${product.fssai_lic_no}`}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    }

                                                    {product?.brand_name ? (
                                                        <div className={styles.productoverview}>
                                                            <div className={styles.producttags}>
                                                                <span className={styles.tagtitle}>{t("brand")} :</span>
                                                                <span className={styles.tagname}>{product?.brand_name} </span>
                                                            </div>
                                                        </div>
                                                    ) : ""}

                                                    <div className={styles.shareproductcontainer}>
                                                        <span className={styles.shareproduct}>{t("share_product")} :</span>

                                                        <ul className={styles.shareproduct}>
                                                            <li className='share-product-icon'><WhatsappShareButton url={`${setting.setting && setting.setting.web_settings.website_url}product/${product.slug}`}><WhatsappIcon size={32} round={true} /></WhatsappShareButton></li>
                                                            <li className='share-product-icon'><TelegramShareButton url={`${setting.setting && setting.setting.web_settings.website_url}product/${product.slug}`}><TelegramIcon size={32} round={true} /></TelegramShareButton></li>
                                                            <li className='share-product-icon'><FacebookShareButton url={`${setting.setting && setting.setting.web_settings.website_url}product/${product.slug}`}><FacebookIcon size={32} round={true} /></FacebookShareButton></li>
                                                            <li className='share-product-icon'>
                                                                <button type='button' onClick={() => {
                                                                    navigator.clipboard.writeText(`${setting.setting && setting.setting.web_settings.website_url}product/${product.slug}`);
                                                                    toast.success("Copied Succesfully!!");
                                                                }}> <BiLink size={30} /></button>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                    }
                </Modal.Body>
            </div>
        </Modal>
    );

};

export default QuickViewModal;
