import React from 'react';
import { useSelector } from 'react-redux';
import './press.css';
import Loader from '../loader/Loader';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Press = () => {
    // Access settings from the Redux store
    const setting = useSelector((state) => state.setting);
    const { t } = useTranslation();

    // Fallback handler for image loading errors
    const handleImageError = (e) => {
        if (setting.setting?.web_logo) {
            e.target.src = setting.setting.web_logo; // Set fallback image
        }
    };

    console.log('Current settings:', setting);

    return (
        <section id="about-us" className="about-us">
            {setting.status === 'loading' || !setting.setting ? (
                <Loader screen="full" />
            ) : (
                <>
                    <div className="cover">
                        <img
                            src={setting.setting?.cover_image || 'default-cover-image-url.png'}
                            className="img-fluid"
                            alt="cover"
                            onError={handleImageError}
                        />
                        <div className="title">
                            <h3>{t("Press")}</h3>
                            <span>
                                <Link to="/" className="text-light text-decoration-none">
                                    {t("home")} /
                                </Link>
                            </span>
                            <span className="active">{t("Press")}</span>
                        </div>
                    </div>
                    <div className="container">
                        <div className="about-container">
                            {setting.setting?.press ? (
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: setting.setting.press,
                                    }}
                                ></div>
                            ) : (
                                <p>{t("No information available")}</p>
                            )}
                        </div>
                    </div>
                </>
            )}
        </section>
    );
};

export default Press;
