import styles from './ShowAllCategories.module.css';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../api/api';
import coverImg from '../../utils/cover-img.jpg';
import { useTranslation } from 'react-i18next';
import { setCategory } from '../../model/reducer/categoryReducer';
import { setFilterCategory } from '../../model/reducer/productFilterReducer';


const ShowAllCategories = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const setting = useSelector(state => (state.setting));
  const category = useSelector(state => (state.category));
  //fetch Category
  const fetchCategory = (id = 0) => {
    api.getCategory(id)
      .then(response => response.json())
      .then(result => {
        if (result.status === 1) {
          dispatch(setCategory({ data: result.data }));
        }
      })
      .catch(error => {
        console.log("error ", error);
      }
      );
  };

  useEffect(() => {
    if (category.status === 'loading' && category.category === null) {
      fetchCategory();

    }
  }, [category]);

  useEffect(() => {
    const handleBrowserBack = (e) => {
    };
    window?.addEventListener("popstate", handleBrowserBack);
    return () => {
      window.removeEventListener("popstate", handleBrowserBack);
    };
  }, []);
  const [map, setMap] = useState(new Map());

  const onClickCategory = useCallback(
    (child2) => {
      navigate(`/category/${child2?.slug}/${child2?.id}`);
      dispatch(setFilterCategory({ data: child2.id, parent_id: child2.parent_id, categoryName: child2.name, mainCategory: true, image_url: child2.image_url }));
    },
    [dispatch, navigate]
  );

  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [category]);

  const placeHolderImage = (e) => {
    e.target.src = setting.setting?.web_logo;
  };

  return (
    <section className={styles.allcategories} >
      <div className={styles.cover}>
        <img src={coverImg} onError={placeHolderImage} className='img-fluid' alt="cover"></img>
        <div className={styles.pageheading}>
          <h5 key={"category"}>{t("categories")}</h5>
          <p><Link to="/" className='text-light text-decoration-none' onClick={() => fetchCategory(0)} key={"home"}>{t("home")} / </Link>
            <Link className='text-light text-decoration-none' onClick={() => {
              fetchCategory(0);
            }} key={"category"}>{t("categories")}</Link>
          </p>
        </div>
      </div>

      <div className={`container ${styles.container}`} style={{ padding: "20px 0" }}>
        {category.status === 'loading'
          ? (
            <div className="d-flex justify-content-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )
          : (
            <div className={`row ${styles.categoryRow} justify-content-center`}>

              {category.category.map((ctg, index) => (
                <div className={`col-md-3 col-lg-2 col-6 col-sm-3 my-3 ${styles.content}`} key={index} onClick={() => { onClickCategory(ctg); }}>
                  <div className={styles.card}>
                    <img onError={placeHolderImage} className='card-img-top' src={ctg.image_url} alt='allCategories' loading='lazy' />
                    <div className={styles.cardbody} style={{ cursor: "pointer" }}>
                      <p>
                        {ctg.name}
                      </p>
                    </div>
                  </div>
                </div>
              ))}


            </div>
          )}
      </div>
    </section>
  );
};

export default ShowAllCategories;
