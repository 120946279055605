import React from 'react';
import { useSelector } from 'react-redux';
import './about.css';
import Loader from '../loader/Loader';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const About = () => {
    // Access settings from the Redux store
    const setting = useSelector(state => state.setting);
    const { t } = useTranslation();

    // Fallback to setting.web_logo in case the image fails to load
    const placeHolderImage = (e) => {
        if (setting.setting?.web_logo) {
            e.target.src = setting.setting.web_logo; // Fallback to web_logo in case of error
        }
    };
console.log('setting.setting ',setting)
    return (
        <section id="about-us" className="about-us">
            {setting.status === 'loading' || setting.setting === null ? (
                <Loader screen="full" />
            ) : (
                <>
                    <div className="cover">
{/*                        
                        <img
                            src={setting.setting?.cover_image || 'default-cover-image-url.png'}
                            className="img-fluid"
                            alt="cover"
                            onError={placeHolderImage}
                        /> */}
                        <img
    src={`${process.env.PUBLIC_URL}/Home Decor.png`}
    className="img-fluid"
    alt="cover"
/>

                       
                        <div className="title">
                            <h3>{t("about_us")}</h3>
                            <span>
                                <Link to="/" className="text-light text-decoration-none">
                                    {t("home")} /
                                </Link>
                            </span>
                            <span className="active">{t("about_us")}</span>
                        </div>
                    </div>
                    <div className="container">
                        {/* Dynamically insert "about_us" content */}
                        <div
                            className="about-container"
                            dangerouslySetInnerHTML={{
                                __html: setting.setting?.about_us || '<p>No information available</p>',
                            }}
                        ></div>
                       
                    </div>
                </>
            )}
        </section>
    );
};

export default About;
