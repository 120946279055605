import React, { useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import HomeContainer from './homecontainer/HomeContainer';
import Loader from './loader/Loader';
import ProductContainer from './product/ProductContainer';
import { AiOutlineClose } from 'react-icons/ai';
import { Modal } from 'react-bootstrap';
import styles from './MainContainer.module.css'
import news1 from '../utils/image/3-home-default-370x240.png'
import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import flowerimg from '../utils/image/flower.png';
import scannerimg from '../utils/image/My Ace Bazar - Become a Seller.png';
import scannerimage from '../utils/image/My Ace Bazar - App QR.png';
import phonebanimg from '../utils/image/mobilescreen.png';
import laptopimg from '../utils/image/laptopbanner.png';
import { Link } from 'react-router-dom';
const MainContainer = () => {

    const modalRef = useRef();

    const setting = useSelector(state => state.setting);
    const shop = useSelector(state => state.shop.shop);
    const aboveHomeSlider = shop?.offers?.filter((offer) => offer?.position === "top");
    const BelowHomeSlider = shop?.offers?.filter((offer) => offer.position === "below_slider");
    const BelowCategory = shop?.offers?.filter((offer) => offer.position === "below_category");
    const BelowSectionOfferArray = shop?.offers?.filter((offer) => offer.position === "below_section");

    useEffect(() => {

        if (modalRef.current && setting.setting !== null) {
            modalRef.current.click();
        }
    }, [setting]);

    const [showPop, setShowPop] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const placeHolderImage = (e) => {
        e.target.src = setting.setting?.web_logo;
    };
    const videoIds = [
        'SBfBJkC5Rf4', // Replace with actual YouTube video IDs
        'Ip5wbGPhIVc',
        'JBKk5XzpT20'
    ];
    const opts = {
        height: '150',
        width: '200',
    };
    const tabs = [
        {
            id: 1,
            title: 'My Ace Bazar',
            imageUrl: './myacerod.png',
            content: {
                heading: 'My Ace Bazar',
                text: 'Power of Satellite Imagery and Machine Learning taking Indian Agriculture ecosystem to new frontiers.',
                buttontext: 'Explore More',
                imagephone: './phoneace.png',
                imagelaptop: './laptopace.png'
            }
        },
        {
            id: 2,
            title: 'Join Us My Ace business partner',
            imageUrl: './joinbag.png',
            content: {
                imagejoin: './acejoinbanner.jpg'
            }
        },
    ];
    const [activeTab, setActiveTab] = useState(tabs[0]);
    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    return (
        <>
            {setting.setting === null
                ? <Loader screen='full' />
                : (
                    <>
                        <div className='home-page content' style={{ paddingBottom: "5px", minHeight: "75vh" }}>
                            <HomeContainer OfferImagesArray={aboveHomeSlider} BelowSliderOfferArray={BelowHomeSlider} BelowCategoryOfferArray={BelowCategory} />
                            <ProductContainer showModal={showModal} setShowModal={setShowModal} BelowSectionOfferArray={BelowSectionOfferArray} />
                        </div>
                        {/* Tabs for business */}
                        <div className={`container-fluid ${styles.tabscontainer}`} >
                            <div className='row justify-content-around align-items-center'>
                                <div className='col-lg-5 col-sm-12 col-md-12 col-12'>
                                    <div className={styles.tabs}>
                                        {tabs.map(tab => (
                                            <button
                                                key={tab.id}
                                                className={`${styles.tabbutton, styles.tabname} ${activeTab.id === tab.id ? 'active' : ''}`}
                                                onClick={() => setActiveTab(tab)}
                                            >
                                                <img src={tab.imageUrl} alt={''} className={styles.tabicon} />
                                                <span className={styles.titleclass}>{tab.title}</span>
                                            </button>
                                        ))}
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-12 col-12 col-sm-12'>
                                    <div className={styles.contentclass}>
                                        <h2>{activeTab.content.heading}</h2>
                                        <p>{activeTab.content.text}</p>
                                        {
                                            activeTab.id === 1 && (
                                                <button className='btn'>{activeTab.content.buttontext}</button>
                                            )
                                        }
                                    </div>
                                    {
                                        activeTab.id === 1 && (
                                            <img src={activeTab.content.imagephone} alt={''} className={styles.phoneimg} />
                                        )
                                    }
                                    {
                                        activeTab.id === 1 && (
                                            <img src={activeTab.content.imagelaptop} alt={''} className={styles.phoneimg} />
                                        )
                                    }
                                    {
                                        activeTab.id === 2 && (
                                            <img src={activeTab.content.imagejoin} alt={''} className={styles.joinusimg} />
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='container-fluid'>
                            <div className={styles.landingpage}>
                                <div className='row'>
                                    <div className='col-lg-3 col-md-3 col-sm-3'>
                                        <img src={flowerimg} alt="" style={{ width: '100%', height: '547px' }} className={styles.flowerimage} />
                                    </div>
                                    <div className='col-lg-2 col-md-3 col-sm-2'>
                                        <img src={scannerimg} alt="" className={styles.scanimage} />
                                        <Link to="/landingpage">
                                            <button className={`btn-lg ${styles.businesspartner}`} onClick={goToTop}>Become a my ace bazar business partner</button>
                                        </Link>
                                        <img src={scannerimage} alt='' className={styles.scanimage1} />
                                        <button className={`btn-lg ${styles.businesspartner}`}>Download my ace bazar app</button>
                                    </div>
                                    <div className='col-lg-7 col-md-10 col-sm-7'>
                                        <div className={styles.acebazarbanner}>
                                            <h2>My Ace Bazar</h2>
                                            <p>Unlock new growth opportunities- partner with My Ace Bazar, the leading eCommerce platform connecting businesses with a world of customers.</p>
                                            <div className={styles.imagesalign}>
                                                <img src={phonebanimg} alt="" style={{ height: '420px' }} className={styles.firstimg} />
                                                <img src={laptopimg} alt="" style={{ width: '73%' }} className={styles.secondimg} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Tabs for business end */}
                        {/* <div className='container-fluid videoSection'>
                          
                                <div className="row text-center mb-3 test">
                                    <h2>What People Say About Us</h2>
                                    
                                </div>
                               
                                    <div className='row pt-5'>
                                        <div className='col-lg-4 col-md-6 col-sm-4'> 
                                                <div className="videoboxyoutube"> 
                                                    <div className='videoBox'>
                                                        <YouTube opts={opts} videoId={videoIds[0]} />
                                                    </div> 
                                                    <div className='VideoContentBox'>
                                                        <div className='video-desc'>
                                                            “Join My Ace Bazar Today and Reach 20 Million Shoppers!”
                                                        </div>
                                                        <div className='video-name'>
                                                            Introduction of Business Partner
                                                        </div>
                                                        <div className='video-role'>
                                                            Business Partner
                                                        </div>
                                                    </div>
                                                </div> 
                                        </div> 
 
                                        <div className='col-lg-4 col-md-6 col-sm-4'> 
                                                <div className="videoboxyoutube"> 
                                                    <div className='videoBox'>
                                                        <YouTube opts={opts} videoId={videoIds[1]} />
                                                    </div> 
                                                    <div className='VideoContentBox'>
                                                        <div className='video-desc'>
                                                            “Sell Your Products on My Ace Bazar.”
                                                        </div>
                                                        <div className='video-name'>
                                                        Introduction of Seller
                                                        </div>
                                                        <div className='video-role'>
                                                            Seller
                                                        </div>
                                                    </div>
                                                </div> 
                                        </div> 


                                        <div className='col-lg-4 col-md-6 col-sm-4'> 
                                                <div className="videoboxyoutube"> 
                                                    <div className='videoBox'>
                                                        <YouTube opts={opts} videoId={videoIds[2]} />
                                                    </div> 
                                                    <div className='VideoContentBox'>
                                                        <div className='video-desc'>
                                                            “आज ही My Ace Bazar से जुड़ें और 20 मिलियन खरीदारों तक पहुँचें!”
                                                        </div>
                                                        <div className='video-name'>
                                                        Introduction of Business Partner (in Hindi)
                                                        </div>
                                                        <div className='video-role'>
                                                        Business Partner
                                                        </div>
                                                    </div>
                                                </div> 
                                        </div> 


                                    </div>
                                

                         
                        </div> */}

                        {/* <hr /> */}
                        {/* Latest News Section */}
                        <div className={`container mt-4 ${styles.latestnew}`}>
                            <div className="row text-start mt-5 mb-4">
                                <h2>Latest News</h2>
                                <hr className={styles.hrline} />
                            </div>
                            <div className='row mb-4'>
                                <div className='col-lg-3 col-md-6 col-sm-12'>
                                    <div className='d-flex'>
                                        <div className={styles.BlogOuterBox}>

                                            <img src={news1} alt="" />

                                            <div className={styles.BlogsContent}>
                                                <div className={styles.newstitle}>
                                                    Lorem Ipsum Dolor Site Amet
                                                </div>
                                                <div className={`d-flex ${styles.newssub}`}>
                                                    <div>
                                                        <FontAwesomeIcon icon={faCalendar} />
                                                    </div>
                                                    <span className='latestnewsdate'>14 nov-2024</span>
                                                </div>
                                                <div className={styles.newssub}>
                                                    Lorem Ipsum is simply dummy text of the printing
                                                    and typesetting industry...
                                                </div>
                                                <div className='mt-4'>
                                                    <div className={`${styles.newsbutton} flex justify-content-center align-items-center`}>
                                                        <div className='p-1'>Read More<FontAwesomeIcon className='px-1 mx-2' icon={faArrowRight} /></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-3 col-md-6 col-sm-12'>
                                    <div className='d-flex'>
                                        <div className={styles.BlogOuterBox}>

                                            <img src={news1} alt="" />

                                            <div className={styles.BlogsContent}>
                                                <div className={styles.newstitle}>
                                                    Lorem Ipsum Dolor Site Amet
                                                </div>
                                                <div className={`d-flex ${styles.newssub}`}>
                                                    <div>
                                                        <FontAwesomeIcon icon={faCalendar} />
                                                    </div>
                                                    <span className={styles.latestnewsdate}>14 nov-2024</span>
                                                </div>
                                                <div className={styles.newssub}>
                                                    Lorem Ipsum is simply dummy text of the printing
                                                    and typesetting industry...
                                                </div>
                                                <div className='mt-4'>
                                                    <div className={`${styles.newsbutton} flex justify-content-center align-items-center`}>
                                                        <div className='p-1'>Read More<FontAwesomeIcon className='px-1 mx-2' icon={faArrowRight} /></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-3 col-md-6 col-sm-12'>
                                    <div className='d-flex'>
                                        <div className={styles.BlogOuterBox}>

                                            <img src={news1} alt="" />

                                            <div className={styles.BlogsContent}>
                                                <div className={styles.newstitle}>
                                                    Lorem Ipsum Dolor Site Amet
                                                </div>
                                                <div className={`d-flex ${styles.newssub}`}>
                                                    <div>
                                                        <FontAwesomeIcon icon={faCalendar} />
                                                    </div>
                                                    <span className={styles.latestnewsdate}>14 nov-2024</span>
                                                </div>
                                                <div className={styles.newssub}>
                                                    Lorem Ipsum is simply dummy text of the printing
                                                    and typesetting industry...
                                                </div>
                                                <div className='mt-4'>
                                                    <div className={`${styles.newsbutton} flex justify-content-center align-items-center`}>
                                                        <div className='p-1'>Read More<FontAwesomeIcon className='px-1 mx-2' icon={faArrowRight} /></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-3 col-md-6 col-sm-12'>
                                    <div className='d-flex'>
                                        <div className={styles.BlogOuterBox}>

                                            <img src={news1} alt="" />

                                            <div className={styles.BlogsContent}>
                                                <div className={styles.newstitle}>
                                                    Lorem Ipsum Dolor Site Amet
                                                </div>
                                                <div className={`d-flex ${styles.newssub}`}>
                                                    <div>
                                                        <FontAwesomeIcon icon={faCalendar} />
                                                    </div>
                                                    <span className={styles.latestnewsdate}>14 nov-2024</span>
                                                </div>
                                                <div className={styles.newssub}>
                                                    Lorem Ipsum is simply dummy text of the printing
                                                    and typesetting industry...
                                                </div>
                                                <div className='mt-4'>
                                                    <div className={styles.newsbutton}>
                                                        <div className='p-1'>Read More<FontAwesomeIcon className='px-1 mx-2' icon={faArrowRight} /></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {parseInt(setting.setting.popup_enabled) === 1 ?
                            (
                                <>
                                    <Modal className={styles.HomePopup}
                                        centered
                                        show={showPop}
                                        onBackdropClick={() => setShowPop(false)}
                                        backdrop={"static"}
                                    >
                                        <Modal.Header onClick={() => { setShowPop(false); }}>
                                            <AiOutlineClose size={32} fill='#fff' />
                                        </Modal.Header>
                                        <Modal.Body>
                                            <img src={setting.setting.popup_image} alt='popup_image' onClick={() => {
                                                if (setting.setting?.popup_type === "popup_url") {
                                                    window.location = setting.setting.popup_url;
                                                }
                                                else if (setting.setting?.popup_type === "category") {

                                                }

                                            }}
                                                onError={placeHolderImage}></img>
                                        </Modal.Body>
                                    </Modal>

                                </>
                            ) : null}
                    </>)}
        </>

    );
};

export default MainContainer;
