import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styles from './Thank.module.css';
import OrderStatus from "../thank/OrderStatus"; // Imported but not used

const ThankYou = () => {
  const { search, pathname } = useLocation();
  const navigate = useNavigate();

  // Get the order ID from the URL
  const urlParams = new URLSearchParams(search);
  const orderIdFromUrl = urlParams.get("orderId");

  // If the order ID is not present in the URL, use a default value
  const orderId = orderIdFromUrl || "defaultOrderId"; // Provide a default value

  useEffect(() => {
    // Check if orderId is already present in the URL
    if (!orderIdFromUrl) {
      // Add the orderId parameter to the URL without reloading
      navigate(`${pathname}?orderId=${orderId}`, { replace: true });
    }
  }, [search, pathname, navigate, orderIdFromUrl, orderId]);

  return (
    <div className={styles.thankcontainer}>
      <div className={styles.thankcontent}>
        <div className={styles.thankcard}>
          <img
            src="https://img.freepik.com/premium-vector/colorful-indian-lady-namaste-gesture_343646-13.jpg"
            alt="Welcome"
            className={styles.thankimage}
          />
          <h2>Thanks for the Order</h2>
          <p className={styles.thankstext}>
            Great news! Your order is all set to hit the road. We're packing
            it up with care and it'll be on its way to you in no time.
          </p>

          {/* Order ID Section */}
                {/* Order ID Section */}
      <div className={styles.orderidsection}>
        <p>Order ID: <strong>{orderId}</strong></p>
      </div>

      {/* Order Status Section */}
   

      {/* Contact Information Section */}
      <div className={styles.contactinfo}>
       <p>
  <span className={styles.text}>Question?</span> Call{" "}
  <a href="tel:7877032001" data-rr-ui-dropdown-item="" className="dropdown-item">
    <strong>7877032001</strong>
  </a>{" "}
  or Email Us at{" "}
  <a href="mailto:support@myacebazar.com" className={styles.emaillink}>
    <img 
      src="https://img.icons8.com/material-outlined/24/000000/email.png" // Replace with your email icon URL
      alt="Email Icon"
      className={styles.emailicon}
    /> 
    <strong>myacebazar@gmail.com</strong>
  </a>
</p>
        <p>Payment invoice has been sent to your email.</p>
      </div>

      {/* Buttons Section */}
      <div>
        <button
          className={styles.buttontrack}
          onClick={() => navigate(`/profile/orders/${orderId}`)}
        >
          Track Order
        </button>
        <button
          className={styles.buttonviewall}
          onClick={() => navigate(`/profile/orders`)}
        >
          View All Orders
        </button>
      </div>
    </div>
  </div>
</div>
  );
};

export default ThankYou;