import React, { useEffect, useState } from 'react';
import styles from './CategoryChild.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import coverImg from '../../utils/cover-img.jpg';
import { setSelectedCategory } from '../../model/reducer/categoryReducer';
import Slider from 'react-slick';
import { ShoppingCartOutlined } from '@ant-design/icons';
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { Layout, Menu, Slider as RangeSlider } from 'antd';
import CategoryProductList from './CategoryProductList';
import { setFilterCategory, setFilterMinMaxPrice } from '../../model/reducer/productFilterReducer';
import No_Orders from "../../utils/zero-state-screens/No_Orders.svg";
import api from '../../api/api';
import { setCategoryById } from '../../model/reducer/categoryByIdReducer';

const { Sider } = Layout;
const CategoryChild = () => {
    const {id:urlCategoryId } = useParams();
    const { slug } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const setting = useSelector(state => (state.setting));
    const [rangeValue, setRangeValue] = useState([0, 2000]);
    const filter = useSelector((state) => state.productFilter);
    const categories = useSelector((state) => state.categories.categories);

    useEffect(() => {
        getCategoryById(urlCategoryId)
    }, [urlCategoryId]);

    const getCategoryById = (id) => {
        api.getCategoryById(id)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === 1) {
                    dispatch(setCategoryById({ data: result.data }));
                }
            });
    };

    const placeHolderImage = (e) => {
        e.target.src = setting.setting?.web_logo;
    };

    const handleGetCategory = (cat) => {
        navigate(`/category/${cat?.slug}/${cat?.id}`);
        dispatch(setFilterCategory({ data: cat.id, categoryName: cat.name, image_url: cat.image_url }));
    };

    useEffect(() => {
        if (!location.pathname.startsWith('/category/') || location.pathname === '/category/all') {
            dispatch(setSelectedCategory(null));
        }
    }, [location.pathname, dispatch]);

    const CustomPrevButton = ({ currentSlide, slideCount, ...props }) => (
        <button {...props} type="button" className={`${styles.slickprev} slick-prev`}>
            <FaChevronLeft fill='black' size={30} className={`${styles.prevarrow} prev-arrow`} />
        </button>
    );

    const CustomNextButton = ({ currentSlide, slideCount, ...props }) => (
        <button {...props} type="button" className={`${styles.slicknext} slick-next`}>
            <FaChevronRight fill='black' size={30} className={`${styles.nextarrow} next-arrow`} />
        </button>
    );

    const slidesetting = {
        infinite: true,
        slidesToShow: 6,
        slidesPerRow: 1,
        initialSlide: 0,
        speed: 500,
        autoplay: false,
        autoplaySpeed: 1500,
        centerMargin: "10px",
        margin: "20px",
        slidesToShow: Math.min(5, categories?.length),
        slidesToScroll: 1,

        prevArrow:
            <CustomPrevButton />,
        nextArrow:
            <CustomNextButton />
        ,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 425,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },

            },

        ],
    };

    const onClickSeconMenu = (cat) => {
        navigate(`/category/${cat?.slug}/${cat?.id}`);
        dispatch(setFilterCategory({ data: cat.id, categoryName: cat.name, image_url: cat.image_url }));

    };

    const sidebarCategory = categories?.map((cat, index) => {
        return {
            key: cat.id,
            icon: React.createElement(ShoppingCartOutlined),
            label: cat.name.length > 17 ? `${cat.name.slice(0, 17)}...` : cat.name,
            title: cat.name,
            onClick: () => onClickSeconMenu(cat),
        };
    });

    const handleRangeSliderChange = (value) => {
        setRangeValue(value);
        dispatch(
            setFilterMinMaxPrice({
                data: {
                    min_price: value[0],
                    max_price: value[1],
                },
            })
        );
    }

    return (
        <>
            <section className={styles.allcategories}>
                <div className={styles.cover}>
                    <img src={filter.image_url ? filter.image_url : coverImg} onError={placeHolderImage} className='img-fluid' alt="cover"></img>
                    <div className={styles.pageheading}>
                        <p>
                            <Link to="/" className={`${styles.categoryName} text-light text-decoration-none`} key={"home"}>{filter.categoryName || slug}</Link>
                        </p>
                    </div>
                </div>
                {categories?.length > 0 ?
                    <Layout>
                        <Sider width={220} className={`${styles.siderCss} site-layout-background`}>
                            <div className={styles.siderHeading1}>
                                <div className={styles.siderHeading}>
                                    Categories
                                </div>
                                <div className={styles.sliderMenu}>
                                    <Menu
                                        mode="inline"
                                        defaultSelectedKeys={['1']}
                                        defaultOpenKeys={['1']}
                                        style={{
                                            height: '100%',
                                            borderRight: 0,
                                        }}
                                        items={sidebarCategory?.map((item) => item)}
                                    />
                                </div>
                                <div className={styles.Range}>
                                    <div className={styles.siderHeading}>
                                        Filter by Price
                                    </div>
                                    <div className={styles.Range1}>
                                        <div className={`${styles.rangeinfo} row justify-content-center`}>
                                            <span className={`${styles.minprice} col`}>Min: {rangeValue[0]}</span>
                                            <span className={`${styles.maxprice} col`}>Max: {rangeValue[1]}</span>
                                        </div>
                                        <RangeSlider
                                            min={0}
                                            max={5000}
                                            range={{
                                                draggableTrack: true,
                                            }}
                                            defaultValue={[0, 2000]}
                                            onChange={handleRangeSliderChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Sider>
                        <Layout className={styles.categoryProductLayout}>
                            <div className={`container ${styles.container}`}>
                                {categories?.length > 0 ? (
                                    <div className={`row justify-content-center w-700 h-800 mt-5 mb-5`}>
                                        <Slider {...slidesetting}>
                                            {categories?.map((ctg, index) => (
                                                <div
                                                    className={`my-2 ${styles.content}`}
                                                    key={index}
                                                    onClick={() => handleGetCategory(ctg)}
                                                >
                                                    <div className={categories.length <= 3 ? `card ${styles.card, styles.customcard1}` : `card ${styles.card, styles.customcard}`}>
                                                        <img
                                                            onError={placeHolderImage}
                                                            className="card-img-top"
                                                            src={ctg.image_url}
                                                            alt="allCategories"
                                                            loading="lazy"
                                                        />
                                                        <div className={styles.cardbody}>
                                                            <p>{ctg.name}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </Slider>
                                    </div>
                                ) : (
                                    ""
                                )}
                                <CategoryProductList />
                                {categories?.length === 0 ?
                                    <>
                                        <div className={styles.noproduct} style={{
                                            height: "50vh", width: "100%", display: "flex", alignItems: "center",
                                            justifyContent: "center", textAlign: "center", fontSize: "24px"
                                        }}>
                                            <p>No Categories Found</p>
                                        </div>
                                    </>
                                    : null}
                            </div>
                        </Layout>
                    </Layout> :
                    <div className={styles.noproduct}>
                        <img
                            src={No_Orders}
                            style={{ width: "15%" }}
                            alt="no-product"
                            className="img-fluid"
                        />
                        <p>No Categories Found</p>
                    </div>
                }

            </section>

        </>
    );

};

export default CategoryChild;
